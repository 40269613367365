import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Row, Col, Badge } from 'react-bootstrap';
import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useMutation, useFragment } from 'react-relay';
import { CaseQuery } from './__generated__/CaseQuery.graphql';
import { CaseDeleteMutation } from './__generated__/CaseDeleteMutation.graphql';
import { CaseUpdateMutation } from './__generated__/CaseUpdateMutation.graphql';
import { CaseLinkItem_link$key as linkFragment } from './__generated__/CaseLinkItem_link.graphql';
import { CaseIndividualItem_individual$key as individualFragment } from './__generated__/CaseIndividualItem_individual.graphql';
import { CaseAgencyItem_caseAgency$key as caseAgencyFragment } from './__generated__/CaseAgencyItem_caseAgency.graphql';
import CaseAgencyModal from './CaseAgencyModal';
import CaseLinkModal from './CaseLinkModal';
import CaseIndividualModal from './CaseIndividualModal';
import styled from 'styled-components';
import { handleError, urlFor } from '../../Utils';

const HiddenFaIcon = styled.i`
  visibility: hidden;
`;

const HoverEditLi = styled.li`
cursor: pointer;
&:hover ${HiddenFaIcon} {
  visibility: visible;
}
`;

interface LinkItemProps {
  link: linkFragment,
  setModalId: Function
};
const CaseLinkItem = ({ link, setModalId }: LinkItemProps) => {
  const row = useFragment<linkFragment>(
    graphql`
    fragment CaseLinkItem_link on CaseLink {
          id
          title
          description
          url
          display
      }
    `,
    link
  );

  const id = row?.id;
  const title = row?.title;
  const description = row?.description;
  const url = row?.url;
  const display = row?.display;

  return (
    <HoverEditLi>
      <a href={url} target='_blank' rel='noreferrer'>
        {title || url}{!display && (<Badge pill bg='warning' text='dark' className='ml-1'>Hidden</Badge>)}
      </a><HiddenFaIcon className='fa fa-solid fa-edit ml-1' onClick={setModalId.bind(this, id)} />
      {description && (<p>{description}</p>)}
    </HoverEditLi>
  );
};

interface IndividualItemProps {
  individual: individualFragment,
  setModalId: Function
};
const CaseIndividualItem = ({ individual, setModalId }: IndividualItemProps) => {
  const row = useFragment<individualFragment>(
    graphql`
    fragment CaseIndividualItem_individual on CaseIndividual {
      id
      missingFrom 
      givenName 
      familyName
      fullName
      nickname
      birthdate
      dateOfDeath
      missingSince
      display
      primary
    }`, individual);

  const id = row?.id;
  const givenName = row?.givenName;
  const familyName = row?.familyName;
  const fullName = row?.fullName;
  const nickname = row?.nickname;
  const display = row?.display;
  const primary = row?.primary;
  const displayName = fullName || (givenName && familyName && `${givenName} ${familyName}`) || givenName || familyName || nickname;

  return (
    <HoverEditLi onClick={setModalId.bind(this, id)}>
      {displayName}
      {!display && (<Badge pill bg='warning' text='dark' className='ml-1'>Hidden</Badge>)}
      {primary && (<Badge pill bg='primary' text='light' className='ml-1'>Primary</Badge>)}
      <HiddenFaIcon className='fa fa-solid fa-edit ml-1' />
    </HoverEditLi>
  );
};

interface CaseAgencyItemProps {
  caseAgency: caseAgencyFragment,
  setModalId: Function
};
const CaseAgencyItem = ({ caseAgency, setModalId }: CaseAgencyItemProps) => {
  const row = useFragment<caseAgencyFragment>(
    graphql`
    fragment CaseAgencyItem_caseAgency on CaseAgency {
          id
          agency {
            name
          }
          caseNumber
          primary
          display
      }
    `,
    caseAgency
  );

  const id = row?.id;
  const agency = row?.agency;
  const caseNumber = row?.caseNumber;
  const display = row?.display;
  const primary = row?.primary;

  return (
    <HoverEditLi onClick={setModalId.bind(this, id)}>
      {agency?.name}{caseNumber && ` [${caseNumber}]`}
      {!display && (<Badge pill bg='warning' text='dark' className='ml-1'>Hidden</Badge>)}
      {primary && (<Badge pill bg='primary' text='light' className='ml-1'>Primary</Badge>)}
      <HiddenFaIcon className='fa fa-solid fa-edit ml-1' />
    </HoverEditLi>
  );
};

const Case = ({ id }: { id: string }) => {
  const newRecord = id === 'new';
  const [isEditing, setEditing] = useState<boolean>(newRecord);
  const [caseLinkModalId, setCaseLinkModalId] = useState<string | null>(null);
  const [caseIndividualModalId, setCaseIndividualModalId] = useState<string | null>(null);
  const [caseAgencyModalId, setCaseAgencyModalId] = useState<string | null>(null);

  // Notice we pull back the content and identifier even though its not used in the onCompleted. This is so relay can update the store values automatically when updated
  const [commitUpdate, updateInFlight] = useMutation<CaseUpdateMutation>(
    graphql`
      mutation CaseUpdateMutation($id: ID, $title: String, $description: String, $othramCaseNumber: String, $namusId: String) {
        caseUpdate(id: $id, title: $title, description: $description, othramCaseNumber: $othramCaseNumber, namusId: $namusId) {
          case {
            id
            title
            description
            namusId
            othramCaseNumber
          }
        }
      }
    `);

  const [commitDelete, deleteInFlight] = useMutation<CaseDeleteMutation>(
    graphql`
          mutation CaseDeleteMutation($id: ID!) {
            softDelete(id: $id) {
              success
            }
          }
        `);
  let row;
  if (!newRecord) {
    ({ row } = useLazyLoadQuery<CaseQuery>(
      graphql`
        query CaseQuery($id: ID!){
          row: node(id:$id){
            ... on Case {
              title
              description
              namusId
              othramCaseNumber
              links{
                id
                ...CaseLinkItem_link
              }
              individuals {
                id
                ...CaseIndividualItem_individual
              }
              caseAgencies {
                id
                ...CaseAgencyItem_caseAgency
              }
              caseArticle {
                permalink
                slug
              }
            }
          }
        }
      `,
      { id }
    ));
  } else {
    row = null;
  }
  const title = row?.title;
  const slug = row?.caseArticle?.slug;
  const editLink = urlFor('articles.content_dashboard_edit_case', { slug });
  const description = row?.description;
  const agencyRows = (row?.caseAgencies && (row.caseAgencies.length > 0)
    ? row.caseAgencies.map((caseAgency) => {
      return caseAgency && (<CaseAgencyItem caseAgency={caseAgency} key={caseAgency.id} setModalId={setCaseAgencyModalId} />);
    })
    : [<li key='none'>No agencies attached yet.</li>]);
  const linkRows = (row?.links && (row.links.length > 0)
    ? row.links.map((link) => {
      return link && (<CaseLinkItem link={link} key={link.id} setModalId={setCaseLinkModalId} />);
    })
    : [<p key='none'>No links yet.</p>]);
  const individualRows = (row?.individuals && (row.individuals.length > 0)
    ? row.individuals.map((individual) => {
      return individual && (<CaseIndividualItem individual={individual} key={individual.id} setModalId={setCaseIndividualModalId} />);
    })
    : [<li key='none'>No related individuals yet.</li>]);

  const isInFlight = updateInFlight || deleteInFlight;
  const canDelete = !newRecord;

  const pageTitle = (isEditing ? 'Edit ' : '') + (newRecord ? 'New Case' : title);

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  const frmSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    const frm = evt.target as HTMLFormElement;
    evt.preventDefault();

    commitUpdate({
      variables: {
        id: newRecord ? null : id,
        title: frm.caseTitle.value,
        description: frm.description.value
      },
      onCompleted ({ caseUpdate }, errs) {
        if (!errs) {
          const record = caseUpdate?.case;
          const id = record?.id;
          document.location = urlFor('relay.relay_admin_route', { component: 'Cases', id });
        }
      },
      onError: handleError
    });

    return false;
  };

  const btnDelete = () => {
    if (!confirm('This will delete this case everywhere.\nAre you sure?')) {
      return;
    }

    id && commitDelete({
      variables: {
        id
      },
      onCompleted (_, errs) {
        if (!errs) {
          document.location = urlFor('relay.relay_admin_route', { component: 'Cases' });
        } else {
          handleError();
        }
      },
      onError: handleError
    });

    return false;
  };

  return (<>
    <div className='hero-content'>
      <div className='container text-dark page-title text-break text-center'>
        <h1>{pageTitle}</h1>
      </div>
    </div>
    <div className='d-flex flex-column'>
      <Container className='px-3 py-3 border bg-white'>
        {false && (<a href={urlFor('relay.relay_admin_route', { component: 'Cases' })}>&laquo; Back to Cases</a>)}
        <a href={editLink}>&laquo; Back to Case Article</a>
        {isEditing
          ? (<Form onSubmit={frmSubmit}>
            <Row>
              <Col>
                <Form.Group className='mb-3' controlId='caseTitle'>
                  <Form.Label>Title</Form.Label>
                  <Form.Control size='lg' type='text' defaultValue={title || undefined} />
                </Form.Group>
                <Form.Group className='mb-3' controlId='description'>
                  <Form.Label>Description</Form.Label>
                  <Form.Control as='textarea' defaultValue={description || undefined} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                {isInFlight
                  ? (<h1>Saving...</h1>)
                  : (<>
                    <Button
                      type='submit'
                    >{newRecord ? 'Create' : 'Update'}</Button>
                    <Button onClick={setEditing.bind(this, false)} variant='secondary' className='ml-1'>Cancel</Button>
                    {canDelete && (<Button
                      variant='danger'
                      onClick={btnDelete}
                      className='ml-1'
                    >Delete <i className='fa fa-trash'></i></Button>)}
                  </>
                  )}
              </Col>
            </Row>
          </Form>)
          : (<>
            <Row>
              <Col>
                <p>{description}</p>
              </Col>
              <Col>
                <h5>Agencies:</h5>
                <ul>
                  {agencyRows}
                </ul>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5>Individuals:</h5>
                <ul>
                  {individualRows}
                </ul>
              </Col>
              <Col>
                <h5>Links:</h5>
                <ul>
                  {linkRows}
                </ul>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button href={editLink}>Edit</Button>
                <Button onClick={setCaseLinkModalId.bind(this, 'new')} className='ml-1'>New Link <i className='fa fa-plus'></i></Button>
                <Button onClick={setCaseIndividualModalId.bind(this, 'new')} className='ml-1'>New Individual <i className='fa fa-plus'></i></Button>
                <Button onClick={setCaseAgencyModalId.bind(this, 'new')} className='ml-1'>Link Agency <i className='fa fa-plus'></i></Button>
              </Col>
            </Row>
          </>)}
      </Container>
    </div>
    {id && caseLinkModalId && (<CaseLinkModal id={caseLinkModalId} caseId={id} setModalId={setCaseLinkModalId} />)}
    {id && caseAgencyModalId && (<CaseAgencyModal id={caseAgencyModalId} caseId={id} setModalId={setCaseAgencyModalId} />)}
    {id && caseIndividualModalId && (<CaseIndividualModal id={caseIndividualModalId} caseId={id} setModalId={setCaseIndividualModalId} />)}

  </>);
};

(window as any).Case = Case;

export default Case;
