const urlFor = (function () {
  const isSuperset = (set, subset) => {
    for (const elem of subset) {
      if (!set.has(elem)) {
        return false;
      }
    }
    return true;
  };

  // similar to https://github.com/pallets/werkzeug/blob/main/src/werkzeug/routing.py#L316
  function scoreRule (args, rule) {
    let score = 50;

    const argKeys = Object.keys(args);
    // ignore args that match defaults
    const usedArgs = new Set(argKeys.filter((key) => !(rule.defaults && (args[key] === rule.defaults[key]))));

    const compiledArgs = Object.assign({}, rule.defaults || {}, args);
    for (const key in compiledArgs) {
      // delete if argument is a default not used in template or arg matches the default
      if (!usedArgs.has(key) || (rule.defaults && compiledArgs[key] === rule.defaults[key])) {
        delete compiledArgs[key];
        score--;
      }
      if (rule.args && rule.args.has(key)) {
        score--;
      }
    }

    // favor fewer args
    score += usedArgs.size;

    // favor all args required args filled
    if (rule.args && isSuperset(usedArgs, rule.args)) {
      score--;
    }

    return [rule, score, compiledArgs];
  }
  // sort by second element of the arr (the score)
  const sortScore = (a, b) => (a[1] > b[1] ? 1 : (b[1] > a[1] ? -1 : 0));
  // Make a querystring from a js object
  const makeQs = (obj) => { const res = Object.entries(obj).reduce((qs, [key, val]) => qs.set(key, val) || qs, new URLSearchParams()).toString(); return res ? `?${res}` : ''; };

  function urlFor (route, args) {
    args ||= {};
    if (!(route in urlFor.routes)) {
      throw new Error(`Unknown route! ${route}`);
    }
    const { _anchor: anchor } = args;
    delete args._anchor;

    // score the rules based on the args passed
    const rules = urlFor.routes[route].map(scoreRule.bind(null, args));
    for (const [rule, , compiledArgs] of rules.sort(sortScore)) {
      try {
        const [path, qsObj] = rule.func(compiledArgs);
        const qsStr = makeQs(qsObj);
        const url = new URL([path, qsStr].join(''), window.location.origin);

        if (anchor) url.hash = anchor;

        return url.toString();
      } catch (_err) { }
    }
    throw new Error(`Cant build route! ${route}`);
  }
  // js implementation of the standard url converters
  urlFor.converters = { int: parseInt, iso8601: (d) => d.toISOString(), string: (s) => s.toString(), float: parseFloat };
  // a place for converters to stash their enums
  urlFor.enums = {};

  window.urlFor = urlFor;

  return urlFor;
})();

export default urlFor;
