/**
 * @generated SignedSource<<efd2110049abcceb0604021c421874f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ContentBlockUpdateMutation$variables = {
  content?: string | null;
  dateArchived?: any | null;
  draft?: boolean | null;
  id?: string | null;
  identifier?: string | null;
  unarchive?: boolean | null;
};
export type ContentBlockUpdateMutation$data = {
  readonly contentBlockUpdate: {
    readonly contentBlock: {
      readonly content: string;
      readonly dateArchived: any | null;
      readonly draft: boolean;
      readonly id: string;
      readonly identifier: string | null;
    } | null;
  } | null;
};
export type ContentBlockUpdateMutation = {
  response: ContentBlockUpdateMutation$data;
  variables: ContentBlockUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "content"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dateArchived"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "draft"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "identifier"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "unarchive"
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "content",
        "variableName": "content"
      },
      {
        "kind": "Variable",
        "name": "dateArchived",
        "variableName": "dateArchived"
      },
      {
        "kind": "Variable",
        "name": "draft",
        "variableName": "draft"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "identifier",
        "variableName": "identifier"
      },
      {
        "kind": "Variable",
        "name": "unarchive",
        "variableName": "unarchive"
      }
    ],
    "concreteType": "ContentBlockUpdate",
    "kind": "LinkedField",
    "name": "contentBlockUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ContentBlock",
        "kind": "LinkedField",
        "name": "contentBlock",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "content",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "identifier",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dateArchived",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "draft",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ContentBlockUpdateMutation",
    "selections": (v6/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ContentBlockUpdateMutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "8504d64da479c474601d0acef03ec585",
    "id": null,
    "metadata": {},
    "name": "ContentBlockUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation ContentBlockUpdateMutation(\n  $id: ID\n  $content: String\n  $identifier: String\n  $draft: Boolean\n  $unarchive: Boolean\n  $dateArchived: DateTime\n) {\n  contentBlockUpdate(id: $id, content: $content, identifier: $identifier, draft: $draft, unarchive: $unarchive, dateArchived: $dateArchived) {\n    contentBlock {\n      id\n      content\n      identifier\n      dateArchived\n      draft\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a22a6f28665b1df3c06f7a04898d7bd7";

export default node;
