import React from 'react';

const ScrollingLogo = ({ imageLink } : { imageLink: string}) => {
  return (
    <span className='dnasolves-slider-wrapper ml-2 ml-sm-3 text-dark'>
      <img src={imageLink} height='32' className='pr-1 pr-sm-2 align-self-center' alt='DNASolves® logo' />
      <span><span>DNA</span><span>Solves</span></span>
      <div className='dnasolves-slider d-print-none'>
        <div className='dnasolves-slider-text0'>.com</div>
        <div>Robbery</div>
        <div>Assault</div>
        <div>Rape</div>
        <div>Murder</div>
        <div>.com</div>
      </div>
    </span>
  );
};

export default ScrollingLogo;
