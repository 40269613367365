import React, { useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { urlFor } from '../../Utils';
function socialClick (evt: React.MouseEvent<HTMLAnchorElement>) {
  evt.preventDefault();
  const link = evt.currentTarget as HTMLAnchorElement;
  const width = link.dataset.width ? Number(link.dataset.width) : 780;
  const height = link.dataset.height ? Number(link.dataset.height) : 540;
  window.open(link.href, '', `menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=${height},width=${width}`);
  return false;
}
interface QuoteProps {
  title: string,
  url: string,
  author: string,
  quote: string
};

const ArticleQuote = ({ author, quote, title, url }: QuoteProps) => {
  const [show, setShow] = useState<boolean>(false);
  const doShow = setShow.bind(null, true);
  const doHide = setShow.bind(null, false);
  const text = `"${quote}" ${author}`;
  const twitterUrl = new URL('/intent/tweet', 'https://twitter.com');
  twitterUrl.searchParams.append('text', text);
  twitterUrl.searchParams.append('url', url);
  const linkedinUrl = new URL('/sharing/share-offsite/', 'https://www.linkedin.com/');
  linkedinUrl.searchParams.append('url', url);
  linkedinUrl.searchParams.append('title', title);
  linkedinUrl.searchParams.append('summary', text);
  const facebookUrl = new URL('/sharer/sharer.php', 'https://www.facebook.com/');
  facebookUrl.searchParams.append('u', url);
  facebookUrl.searchParams.append('t', title);

  const overlay = (
    <Popover id='quote-share' onMouseEnter={doShow} onMouseLeave={doHide}>
      <Popover.Content>
        <a href={twitterUrl.toString()} target='_blank' rel='noreferrer' onClick={socialClick} data-width='615' data-height='300'><i className='fab fa-2x mr-3 fa-twitter'></i></a>
        <a href={linkedinUrl.toString()} target='_blank' rel='noreferrer' onClick={socialClick} data-width='550' data-height='520'><i className='fab fa-2x mr-3 fa-linkedin'></i></a>
        <a href={facebookUrl.toString()} target='_blank' rel='noreferrer' onClick={socialClick} data-width='750' data-height='450'><i className='fab fa-2x fa-facebook-f'></i></a>
      </Popover.Content>
    </Popover>
  );

  return (<OverlayTrigger
    show={show}
    trigger={['focus', 'hover']}
    delay={{ show: 250, hide: 400 }}
    overlay={overlay}
  >
    <div className='blockquote mx-auto' onMouseEnter={doShow} onMouseLeave={doHide}>
      <div className='quote cursor-pointer'>
        <img className='quote-start' src={urlFor('static', { filename: 'img/quote_start.svg' })} />
        {quote}
        <img className='quote-end' src={urlFor('static', { filename: 'img/quote_start.svg' })} />
      </div>
      <div className='author'>{author}</div>
    </div>
  </OverlayTrigger>);
};

(window as any).ArticleQuote = ArticleQuote;

export default ArticleQuote;
