import React from 'react';
import ReactDom from 'react-dom';
import { Table } from 'react-bootstrap';

const SupportedFormats = () => {
  return (
    <>
      <p>
        We support automated analysis and reports for a number of different types and formats from different data providers.
      </p>
      <p>
        We are constantly working to support more file types and formats. If you upload data
        that we do not currently support, it will be retained and automatically analyzed when support is
        added.
      </p>
      <p>Any supported data type can be compressed in a .ZIP or .GZ file for faster uploads.</p>
      <Table responsive>
        <thead>
          <tr>
            <th>Data Provider</th>
            <th>Data Type</th>
            <th>Supported Format</th>
            <th>How to Download</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>AncestryDNA</td>
            <td>Raw DNA Data</td>
            <td><abbr title='Text file'>TXT</abbr></td>
            <td>
              <a href='https://support.ancestry.com/s/article/Downloading-AncestryDNA-Raw-Data' target='_blank' rel='noreferrer'>
                <i className='fas fa-file-download'></i>
              </a>
            </td>
          </tr>
          <tr>
            <td>23andMe</td>
            <td>Raw Genetic Data</td>
            <td><abbr title='Text file'>TXT</abbr></td>
            <td>
              <a href='https://customercare.23andme.com/hc/en-us/articles/212196868-Accessing-Your-Raw-Genetic-Data'
                target='_blank' rel='noreferrer'>
                <i className='fas fa-file-download'></i>
              </a>
            </td>
          </tr>
          <tr>
            <td>MyHeritage</td>
            <td>Raw DNA Data</td>
            <td><abbr title='Text file'>TXT</abbr></td>
            <td>
              <a href='https://faq.myheritage.com/en/article/how-do-i-download-my-raw-dna-data-file-from-myheritage' target='_blank' rel='noreferrer'>
                <i className='fas fa-file-download'></i>
              </a>
            </td>
          </tr>
          <tr>
            <td rowSpan={3}>Family Tree DNA</td>
            <td>Family Finder</td>
            <td><abbr title='Comma-Separated-Values file'>CSV</abbr></td>
            <td>
              <a href='https://help.familytreedna.com/hc/en-us/articles/4415184836367-Downloading-Family-Finder-Data-'
                target='_blank' rel='noreferrer'>
                <i className='fas fa-file-download'></i>
              </a>
            </td>
          </tr>
          <tr>
            <td><abbr title='Mitochondrial DNA'>mtDNA</abbr></td>
            <td><abbr title='Raw nucleotide sequence file'>FASTA</abbr></td>
            <td>
              <a href='https://www.familytreedna.com/learn/user-guide/mtdna-myftdna/mt-results-page/' target='_blank' rel='noreferrer'>
                <i className='fas fa-file-download'></i>
              </a>
            </td>
          </tr>
          <tr>
            <td><abbr title='Y Chromosome Short Tandem Repeats'>Y-STRs</abbr></td>
            <td><abbr title='Comma-Separated-Values file'>CSV</abbr></td>
            <td>
              <a href='https://www.familytreedna.com/my/y-dna-dys' target='_blank' rel='noreferrer'>
                <i className='fas fa-file-download'></i>
              </a>
            </td>
          </tr>
          <tr>
            <td rowSpan={3}>
              Other<br />
              (Advanced Users)
            </td>
            <td>Un-Aligned Raw Sequencing Data</td>
            <td><abbr title='Raw nucleotide sequence and quality file'>FASTQ</abbr></td>
            <td></td>
          </tr>
          <tr>
            <td>Aligned Raw Sequencing Data</td>
            <td><abbr title='Sequence Alignment Map file'>SAM</abbr>, <abbr title='Binary Alignment Map file'>BAM</abbr>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>Processed Variant Data</td>
            <td><abbr title='Variant Call Format file'>VCF</abbr></td>
            <td></td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

(window as any).loadSupportedFormats = function (domElement: HTMLElement) {
  ReactDom.render(<SupportedFormats />, domElement);
};
