/**
 * @generated SignedSource<<a7b319b64616b8733d30b63d12ecc03d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentBlocksQuery$variables = {};
export type ContentBlocksQuery$data = {
  readonly contentBlocks: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"ContentBlocks_block">;
      } | null;
    } | null>;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    };
  } | null;
};
export type ContentBlocksQuery = {
  response: ContentBlocksQuery$data;
  variables: ContentBlocksQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ContentBlocksQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ContentBlockConnection",
        "kind": "LinkedField",
        "name": "contentBlocks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentBlockEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ContentBlock",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ContentBlocks_block"
                  }
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ContentBlocksQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ContentBlockConnection",
        "kind": "LinkedField",
        "name": "contentBlocks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentBlockEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ContentBlock",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "identifier",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clickCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "impressionCount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e503f061922fad5e654a366f550a8341",
    "id": null,
    "metadata": {},
    "name": "ContentBlocksQuery",
    "operationKind": "query",
    "text": "query ContentBlocksQuery {\n  contentBlocks {\n    edges {\n      node {\n        id\n        ...ContentBlocks_block\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n    }\n  }\n}\n\nfragment ContentBlocks_block on ContentBlock {\n  id\n  identifier\n  clickCount\n  impressionCount\n}\n"
  }
};
})();

(node as any).hash = "108099a445e15360c2a9b9304bd18ade";

export default node;
