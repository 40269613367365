/**
 * @generated SignedSource<<217bd374e0f8b1d41015da45bb1d8a10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CaseLinkModalQuery$variables = {
  hasId: boolean;
  id: string;
};
export type CaseLinkModalQuery$data = {
  readonly caseLink?: {
    readonly description?: string | null;
    readonly display?: boolean;
    readonly id?: string;
    readonly title?: string | null;
    readonly url?: string;
  } | null;
};
export type CaseLinkModalQuery = {
  response: CaseLinkModalQuery$data;
  variables: CaseLinkModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "display",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CaseLinkModalQuery",
    "selections": [
      {
        "condition": "hasId",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "caseLink",
            "args": (v2/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "type": "CaseLink",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CaseLinkModalQuery",
    "selections": [
      {
        "condition": "hasId",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "caseLink",
            "args": (v2/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "type": "CaseLink",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "ef618e2a7aa6f6c91bcb208d5026aa64",
    "id": null,
    "metadata": {},
    "name": "CaseLinkModalQuery",
    "operationKind": "query",
    "text": "query CaseLinkModalQuery(\n  $id: ID!\n  $hasId: Boolean!\n) {\n  caseLink: node(id: $id) @include(if: $hasId) {\n    __typename\n    ... on CaseLink {\n      id\n      title\n      description\n      url\n      display\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "db2a13d125d1f7573747f100f84c83cc";

export default node;
