/**
 * @generated SignedSource<<63747d7391b135a46f35d6b286030d8c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GeoCaseCards_geo$data = {
  readonly caseArticles: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"GeoCaseCards_item">;
      } | null;
    } | null>;
  } | null;
  readonly name: string;
  readonly " $fragmentType": "GeoCaseCards_geo";
};
export type GeoCaseCards_geo$key = {
  readonly " $data"?: GeoCaseCards_geo$data;
  readonly " $fragmentSpreads": FragmentRefs<"GeoCaseCards_geo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GeoCaseCards_geo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "sortByMagic",
          "value": true
        }
      ],
      "concreteType": "CaseArticleConnection",
      "kind": "LinkedField",
      "name": "caseArticles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CaseArticleEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CaseArticle",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "GeoCaseCards_item"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "caseArticles(sortByMagic:true)"
    }
  ],
  "type": "State",
  "abstractKey": null
};

(node as any).hash = "9900d1ef4e3ef71f26321952786c28d7";

export default node;
