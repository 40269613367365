import React from 'react';

/**
 * Display a date formatted.
 * @param {Date} date
 */

type DateFormat = {
  year: string,
  month: string,
  day: string,
  hour: string,
  minute: string,
  dayPeriod: string
}
export const OthramDate = ({ date }: { date: Date }) => {
  const formatter = new Intl.DateTimeFormat('en-us', {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
    timeZone: 'America/Chicago'
  });

  const dateParts: DateFormat = {
    year: '',
    month: '',
    day: '',
    hour: '',
    minute: '',
    dayPeriod: ''
  };
  formatter.formatToParts(date).forEach(({ type, value }) => { dateParts[type] = value; });

  return <span>
    {dateParts.day}-{date.toLocaleString('en-us', { month: 'short' })}-{dateParts.year} {dateParts.hour.padStart(2, '0')}:{dateParts.minute.padStart(2, '0')} {dateParts.dayPeriod}
  </span>;
};
