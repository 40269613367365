/**
 * @generated SignedSource<<058930d8ef80c70f6857c8c96be928fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ContributeStep2Mutation$variables = {
  canContact: boolean;
  email: string;
  name: string;
  paymentIntentId: string;
};
export type ContributeStep2Mutation$data = {
  readonly completePayment: {
    readonly result: boolean | null;
  } | null;
};
export type ContributeStep2Mutation = {
  response: ContributeStep2Mutation$data;
  variables: ContributeStep2Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "canContact"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "paymentIntentId"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "canContact",
        "variableName": "canContact"
      },
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "paymentIntentId",
        "variableName": "paymentIntentId"
      }
    ],
    "concreteType": "CompletePayment",
    "kind": "LinkedField",
    "name": "completePayment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ContributeStep2Mutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "ContributeStep2Mutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "fd6fb4254b487ecd8f5641684bea4a60",
    "id": null,
    "metadata": {},
    "name": "ContributeStep2Mutation",
    "operationKind": "mutation",
    "text": "mutation ContributeStep2Mutation(\n  $email: String!\n  $name: String!\n  $canContact: Boolean!\n  $paymentIntentId: String!\n) {\n  completePayment(email: $email, name: $name, canContact: $canContact, paymentIntentId: $paymentIntentId) {\n    result\n  }\n}\n"
  }
};
})();

(node as any).hash = "4cb45d06898c92e3f4f2e072714f8300";

export default node;
