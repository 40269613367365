import React from 'react';
import { Table } from 'react-bootstrap';
import ReactDom from 'react-dom';

interface DNAMatchesTableResult {
  quarter: string;
  kitsTotal: number;
  kitsNondup: number;
  kitsDup: number;
  matchesTotal: number;
  matchesExclDup: number;
  firstCousins: number;
  firstCousinsOnceRemoved: number;
  secondCousins: number;
  thirdCousins: number;
}

const MatchesTable = ({ tableRows, maxCentimorgans }: { tableRows: Array<DNAMatchesTableResult>, maxCentimorgans: number }) => {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th></th>
          <th colSpan={3}>Kits</th>
          <th colSpan={2}>Match pairs</th>
          <th colSpan={4}>Relationships per non-dup kit</th>
        </tr>
        <tr>
          <th></th>
          <th>All</th>
          <th>Excl dup</th>
          <th>Dup</th>
          <th>All</th>
          <th>Excl dup</th>
          <th>&gt; 1C<br />600-{maxCentimorgans} cM</th>
          <th>&gt; 1C1R<br />310-{maxCentimorgans} cM</th>
          <th>&gt; 2C<br />170-{maxCentimorgans} cM</th>
          <th>&gt; 3C<br />58-{maxCentimorgans} cM</th>
        </tr>
      </thead>
      <tbody>
        {tableRows.map((row: DNAMatchesTableResult) => (
          <tr key={row.quarter}>
            <th>{row.quarter}</th>
            <td style={{ textAlign: 'right' }}>{row.kitsTotal.toLocaleString()}</td>
            <td style={{ textAlign: 'right' }}>{row.kitsNondup.toLocaleString()}</td>
            <td style={{ textAlign: 'right' }}>{row.kitsDup.toLocaleString()}</td>
            <td style={{ textAlign: 'right' }}>{row.matchesTotal.toLocaleString()}</td>
            <td style={{ textAlign: 'right' }}>{row.matchesExclDup.toLocaleString()}</td>
            <td style={{ textAlign: 'right' }}>{row.firstCousins.toFixed(2)}</td>
            <td style={{ textAlign: 'right' }}>{row.firstCousinsOnceRemoved.toFixed(2)}</td>
            <td style={{ textAlign: 'right' }}>{row.secondCousins.toFixed(2)}</td>
            <td style={{ textAlign: 'right' }}>{row.thirdCousins.toFixed(2)}</td>
          </tr >
        ))}
      </tbody >
    </Table >
  );
};

(window as any).loadMatchesTable = function (domEle: HTMLElement, props: { tableRows: Array<DNAMatchesTableResult>, maxCentimorgans: number }) {
  ReactDom.render(<MatchesTable tableRows={props.tableRows} maxCentimorgans={props.maxCentimorgans} />, domEle);
};
