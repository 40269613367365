import React from 'react';
import { underscore } from 'inflection';
import { PayloadError as RelayPayloadError } from 'relay-runtime';
import { MapOrEntries, useMap } from 'usehooks-ts';
import { Form } from 'react-bootstrap';

const handleError = (error?: {message:string}) => alert(error?.message || 'Operation failed. please refresh and try again or contact support');
const XL = 'xl' as any; // lint related
const urlFor = (window as any).urlFor;
const dataLayer = (window as any).dataLayer;
const stripePublicKey = (window as any).stripePublicKey;
const retryEvery = 30 * 24 * 60 * 60 * 1000; // millis in a month
const popupAfter = 1000 * 1.5 * 60; // 1.5 min avg reading time
const hideDelay = 5000; // How long to display thank you
type PayloadError = RelayPayloadError & { extensions: { field?: string, fields?: string[] } };

const useErrors = (initialErrors?: MapOrEntries<string, string> | undefined) => {
  const [errors, { set: setError, remove: removeError }] = useMap<string, string>(initialErrors);
  const clearErrorHelper = (name: string) => (errors.has(name) ? removeError.bind(null, name) : undefined);
  const FormErrorControl = (name: string) => <Form.Text className={`invalid-feedback ${errors.has(name) ? 'd-block' : ''}`}>{errors.get(name)}</Form.Text>;
  return { errors, setError, removeError, clearErrorHelper, FormErrorControl };
};

const variablesFromForm = (form: HTMLFormElement, nullableFields: string[], aliases: {[key: string]: string}, extra: {[key: string]:string| string[] | undefined}) => {
  const vars: {[key: string]: string | string[] | undefined} = extra || {};
  const nullFields: string[] = [];
  Array.from(form.elements).forEach((input) => {
    const name = input.id;
    let forceSave = false;
    let val;
    if (input.tagName === 'INPUT' && (input as HTMLInputElement).type === 'checkbox') {
      forceSave = true;
      val = (input as HTMLInputElement).checked;
    } else {
      val = (input as HTMLInputElement).value;
    }

    const alias = aliases[name] || name;
    if (val || forceSave) {
      vars[alias] = val;
    } else if (nullableFields.includes(alias)) {
      nullFields.push(underscore(alias).toUpperCase());
    }
  });
  if (nullFields) {
    vars.nullFields = nullFields;
  }

  return vars as unknown;
};

export { useErrors, handleError, XL, urlFor, stripePublicKey, retryEvery, popupAfter, hideDelay, dataLayer, variablesFromForm };
export type { PayloadError };
