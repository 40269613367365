import React, { useState, useEffect, useRef } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { Modal, Button } from 'react-bootstrap';

import { useMutation } from 'react-relay';
import { NewsletterSignupModalMutation } from './__generated__/NewsletterSignupModalMutation.graphql';
import { urlFor, hideDelay, popupAfter, retryEvery } from '../../Utils';
import ReCAPTCHA from 'react-google-recaptcha';

const timeKey = 'signupLastShown';
const alreadySignedUp = !!localStorage.getItem('signedUp');

interface NewsletterSignupModalProps {
  recaptchaSiteKey: string | null;
}
const NewsletterSignupModal = ({ recaptchaSiteKey }: NewsletterSignupModalProps) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const formRef = useRef<HTMLFormElement>(null);
  const [step, setStep] = useState<number>(0);
  const emailError = step === -1;
  const reset = () => { if (!(window as any).popupShowing) setStep(1); };
  const show = (step === -1) || (step > 0 && step < 3);
  const setEmailError = setStep.bind(null, -1);
  const signedUp = step === 2;
  const setHidden = () => { (window as any).popupShowing = false; setStep(3); };
  const setSignedUp = () => setTimeout(setHidden, hideDelay) && setStep(2);
  useEffect(() => {
    const now = Date.now();
    const signupLastShown = Number(localStorage.getItem(timeKey) || '0');
    if (!alreadySignedUp && (now - signupLastShown) > retryEvery) {
      localStorage.setItem(timeKey, now.toString());
      const timer = setTimeout(reset, popupAfter);
      return () => clearTimeout(timer);
    }
  }, []);

  const [signup, signupInFlight] = useMutation<NewsletterSignupModalMutation>(
    graphql`
      mutation NewsletterSignupModalMutation($email: String!, $recaptcha: String!) {
        signupEmailNewsletter(email: $email, recaptcha: $recaptcha) {
          result
        }
      }
    `);

  const frmSubmit = async (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();

    const frm = formRef.current;
    if (!frm) return;

    if (!frm.email.value) {
      return setEmailError();
    }

    let recaptcha = '';
    if (recaptchaRef.current) {
      recaptcha = await recaptchaRef.current?.executeAsync() ?? '';
      if (!recaptcha) return;
    }

    signup({
      variables: {
        email: frm.email.value,
        recaptcha
      },
      onCompleted (_, errs) {
        (errs ? setEmailError : setSignedUp)();
      }
    });

    return false;
  };

  const backgroundUrl = urlFor('static', { filename: 'img/logo_white.svg' });

  return (
    <Modal id='newsletter-signup-modal' onHide={setHidden} show={show} onShow={() => { (window as any).popupShowing = true; }}>
      <Modal.Body>
        {recaptchaSiteKey && <ReCAPTCHA
          ref={recaptchaRef}
          size='invisible'
          sitekey={recaptchaSiteKey}
        />}
        <div className='heart-logo' style={{
          height: '130%',
          zIndex: 10,
          width: '100%',
          position: 'absolute',
          backgroundPosition: 'center top',
          opacity: 0.1,
          transform: 'rotate(-20deg)',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url('${backgroundUrl}')`
        }}></div>
        <h5>Get Weekly DNASolves® Updates</h5>
        <p>
          New cases, solved cases, and cases that need funding &mdash; straight to your inbox!
        </p>

        {signedUp
          ? (<h3>Signup complete; Please verify your email</h3>)
          : (<form ref={formRef} className='newsletter'>
            <div className='col-12 px-0 mx-0 text-center'>
              <p>Enter your email below to get the latest case updates direct to your inbox.</p>
              <div className='form-group'>
                <input className={`form-control ${emailError ? 'is-invalid' : ''}`} onChange={emailError ? reset : undefined} name='email' placeholder='Email address' type='email' aria-describedby='emailHelp' required />
                {emailError && (<div className='invalid-feedback'>
                  {formRef.current?.email.value ? 'Email invalid. Please check the format' : 'Please enter your email'}
                </div>)}
                <small id='emailHelp' className='form-text text-muted'>We&apos;ll never share your email with anyone else.</small>
              </div>

            </div>
          </form >)}

        <div className='d-flex flex-row-reverse'>
          <Button disabled={signupInFlight} type='button' onClick={frmSubmit} className='px-2 px-md-3 ml-2 mt-2'>Signup</Button>
          <Button variant='secondary' className='mt-2 px-2 px-md-3' onClick={setHidden}>No Thanks</Button>
        </div>
      </Modal.Body >
    </Modal >
  );
};

(window as any).NewsletterSignupModal = NewsletterSignupModal;

export default NewsletterSignupModal;
