import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Table, Button } from 'react-bootstrap';
import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useFragment } from 'react-relay';
import { AgencyManagementQuery } from './__generated__/AgencyManagementQuery.graphql';
import { AgencyManagementItem_item$key as itemFragment } from './__generated__/AgencyManagementItem_item.graphql';
import AgencyManagementModal from './AgencyManagementModal';
import styled from 'styled-components';

const { Suspense } = React;

const HiddenFaIcon = styled.i`
  visibility: hidden;
`;
const HoverEditTd = styled.td`
  cursor: pointer;
  &:hover ${HiddenFaIcon} {
    visibility: visible;
  }
`;

interface ItemProps {
  item: itemFragment,
  setModalId: Function
};
const AgencyManagementItem = ({ item, setModalId }: ItemProps) => {
  const row = useFragment<itemFragment>(
    graphql`
      fragment AgencyManagementItem_item on Agency {
        id
        name
        wikipediaUrl
        instagramUrl
        facebookUrl
        twitterUrl
        websiteUrl
      }`, item);

  const id = row?.id;
  const name = row?.name;
  const wikipediaUrl = row?.wikipediaUrl;
  const instagramUrl = row?.instagramUrl;
  const facebookUrl = row?.facebookUrl;
  const twitterUrl = row?.twitterUrl;
  const websiteUrl = row?.websiteUrl;

  return (
    <tr>
      <HoverEditTd onClick={() => setModalId(id)}>{name} <HiddenFaIcon className='fa fa-solid fa-edit' /></HoverEditTd>
      <td>{wikipediaUrl && (<a href={wikipediaUrl}><i className='fab fa-wikipedia-w'></i> Wikipedia</a>)}</td>
      <td>{instagramUrl && (<a href={instagramUrl}><i className='fab fa-instagram'></i> Instagram</a>)}</td>
      <td>{facebookUrl && (<a href={facebookUrl}><i className='fab fa-facebook-square'></i> Facebook</a>)}</td>
      <td>{twitterUrl && (<a href={twitterUrl}><i className='fab fa-twitter-square'></i> Twitter</a>)}</td>
      <td>{websiteUrl && (<a href={websiteUrl}><i className='fa fa-globe'></i> Homepage</a>)}</td>
    </tr>
  );
};

interface CasesProps {
  pageTitle: string,
};

const AgencyManagement = (props: CasesProps) => {
  const [modalId, setModalId] = useState<string | null>(null);
  const addItem = () => setModalId('new');
  useEffect(() => {
    document.title = props.pageTitle;
  }, [props.pageTitle]);

  const data = useLazyLoadQuery<AgencyManagementQuery>(
    graphql`
      query AgencyManagementQuery {
        agencies {
          edges {
            node {
              ...AgencyManagementItem_item
            }
          }
          pageInfo {
            hasNextPage
          }
        }
      }
  `, {}
  );

  const agencies = data.agencies?.edges;
  const agencyRows = (
    (agencies && agencies.length > 0)
      ? agencies.map((row, idx) => {
        return (row?.node && <AgencyManagementItem setModalId={setModalId} item={row.node} key={idx} />);
      })
      : [(<tr key='none'><td colSpan={6}>No agencies yet... <a href='#' onClick={addItem}>Add One</a></td></tr>)]
  );

  const LoadingRow = () => (<tr>{[...Array(6)].map((idx) => <td key={idx}>...</td>)}</tr>);
  const LoadingRows = <>{[...Array(3)].map((idx) => <LoadingRow key={idx} />)}</>;

  return (<>
    <div className='hero-content'>
      <Container className='text-dark page-title text-break text-center'>
        <h1>{props.pageTitle}</h1>
      </Container>
    </div>
    <div className='d-flex flex-column'>
      <Container className='px-3 py-3 border bg-white mb-5'>
        <Row>
          <Col>
            <Button onClick={addItem} variant='primary' className='mb-3'>New Agency <i className='fa fa-plus'></i></Button>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Wikipedia</th>
                  <th>Instagram</th>
                  <th>Facebook</th>
                  <th>Twitter</th>
                  <th>Website</th>
                </tr>
              </thead>
              <tbody>
                <Suspense fallback={LoadingRows}>
                  {agencyRows}
                </Suspense>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </div>
    {modalId && (<AgencyManagementModal id={modalId} setModalId={setModalId} />)}
  </>);
};
(window as any).AgencyManagement = AgencyManagement;

export default AgencyManagement;
