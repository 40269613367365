import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useMutation } from 'react-relay';
import { ContentBlockQuery } from './__generated__/ContentBlockQuery.graphql';
import { ContentBlockDeleteMutation } from './__generated__/ContentBlockDeleteMutation.graphql';
import { ContentBlockUpdateMutation } from './__generated__/ContentBlockUpdateMutation.graphql';
import { handleError, urlFor } from '../../Utils';

const ContentBlock = ({ id }: { id: string | null }) => {
  const newRecord = id === 'new';
  // Notice we pull back the content and identifier even though its not used in the onCompleted. This is so relay can update the store values automatically when updated
  const [commitUpdate, updateInFlight] = useMutation<ContentBlockUpdateMutation>(
    graphql`
      mutation ContentBlockUpdateMutation($id: ID, $content: String, $identifier: String, $draft: Boolean, $unarchive: Boolean, $dateArchived: DateTime) {
        contentBlockUpdate(id: $id, content: $content, identifier: $identifier, draft: $draft, unarchive: $unarchive, dateArchived: $dateArchived) {
          contentBlock {
            id
            content
            identifier
            dateArchived
            draft
          }
        }
      }
    `);

  const [commitDelete, deleteInFlight] = useMutation<ContentBlockDeleteMutation>(
    graphql`
          mutation ContentBlockDeleteMutation($id: ID!) {
            contentBlockDelete(id: $id) {
              success
            }
          }
        `);

  const [commitClearData, clearDataInFlight] = useMutation<ContentBlockDeleteMutation>(
    graphql`
          mutation ContentBlockClearDataMutation($id: ID!) {
            contentBlockClearData(id: $id) {
              success
            }
          }
        `);

  let content, identifier, draft;
  let dateArchived: Date | null = null;
  let impressionCount: number = 0;
  let clickCount: number = 0;
  if (!newRecord && id) {
    const { contentBlock } = useLazyLoadQuery<ContentBlockQuery>(
      graphql`
        query ContentBlockQuery($id: ID!){
          contentBlock: node(id:$id){
            ... on ContentBlock {
              identifier
              content
              impressionCount
              clickCount
              dateArchived
              draft
            }
          }
        }
      `,
      { id }
    );
    dateArchived = contentBlock?.dateArchived;
    content = contentBlock?.content;
    draft = contentBlock?.draft;
    identifier = contentBlock?.identifier;
    impressionCount = contentBlock?.impressionCount || 0;
    clickCount = contentBlock?.clickCount || 0;
  } else {
    id = null;
  }

  const isInFlight = updateInFlight || deleteInFlight || clearDataInFlight;
  const canDelete = !newRecord && (clickCount === 0) && (impressionCount === 0);
  const canArchive = !newRecord;
  const canClearData = !newRecord && !canDelete;

  const frmSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    const frm = evt.target as HTMLFormElement;
    evt.preventDefault();

    commitUpdate({
      variables: {
        id,
        content: frm.content.value,
        identifier: frm.identifier.value,
        draft: frm.draft.checked
      },
      onCompleted ({ contentBlockUpdate }, errs) {
        if (!errs) {
          const contentBlock = contentBlockUpdate?.contentBlock;
          const id = contentBlock?.id;
          document.location = urlFor('relay.relay_admin_route', { component: 'ContentBlocks', id });
        }
      },
      onError: handleError
    });

    return false;
  };

  const btnArchive = () => {
    const variables = {
      id,
      dateArchived: dateArchived ? null : new Date(),
      unarchive: dateArchived ? true : null
    };
    id && commitUpdate({
      variables,
      onCompleted (_, errs) {
        if (!errs) {
          location.reload();
        } else {
          handleError();
        }
      },
      onError: handleError
    });

    return false;
  };

  const btnDelete = () => {
    if (!confirm('This will delete this content block everywhere.\nAre you sure?')) {
      return;
    }

    id && commitDelete({
      variables: {
        id
      },
      onCompleted (_, errs) {
        if (!errs) {
          document.location = urlFor('relay.relay_admin_route', { component: 'ContentBlocks' });
        } else {
          handleError();
        }
      },
      onError: handleError
    });

    return false;
  };

  const btnClearData = () => {
    if (!confirm('This will clear this content blocks historical impression and click data.\nAre you sure?')) {
      return;
    }

    id && commitClearData({
      variables: {
        id
      },
      onCompleted (_, errs) {
        if (!errs) {
          // force page reload to requery
          location.reload();
        } else {
          handleError();
        }
      },
      onError: handleError
    });

    return false;
  };

  return (<>
    <div className='hero-content'>
      <div className='container text-dark page-title text-break text-center pt-0'>
        <h1>{newRecord ? 'New Content Block' : 'Edit a Content Block'}</h1>
      </div>
    </div>
    <div className='d-flex flex-column'>
      <div className='container px-3 py-3 border bg-white'>
        <a href={urlFor('relay.relay_admin_route', { component: 'ContentBlocks' })}>&laquo; Back to Content Blocks</a>
        {!newRecord && (<h5>Editing: [CB {identifier}]</h5>)}
        <form onSubmit={frmSubmit}>
          <div className='form-group'>
            <label htmlFor='identifier'>Identifier for the short tag</label>
            <input pattern='^\w+$'
              title='Letters, numbers and underscore only. No spaces allowed'
              className='form-control'
              type='text' name='identifier'
              defaultValue={identifier || undefined} placeholder='Identifier' required />
          </div>
          <div className='form-group'>
            <label htmlFor='content'>HTML content of the block</label>
            <textarea className='form-control' name='content' placeholder='Content' required defaultValue={content}></textarea>
          </div>
          <div className='form-check'>
            <input className='form-check-input' name='draft' type='checkbox' value='true' id='draft' defaultChecked={draft} />
            <label className='form-check-label' htmlFor='draft'>
              Draft
            </label>
          </div>
          {isInFlight
            ? (<h1>Saving...</h1>)
            : (<>
              <button
                type='submit'
                className='btn btn-primary'
              >{newRecord ? 'Create' : 'Update'}</button>
              {canDelete && (<button
                onClick={btnDelete}
                className='btn ml-2'
              >Delete</button>)}
              {canArchive && (<button
                onClick={btnArchive}
                className='btn ml-2'
              >{dateArchived ? 'Unarchive' : 'Archive'}</button>)}
              {canClearData && (<button
                onClick={btnClearData}
                className='btn ml-2'
              >Clear Data</button>)}
            </>
            )}
        </form>
      </div>
    </div>
  </>);
};

(window as any).ContentBlock = ContentBlock;

export default ContentBlock;
