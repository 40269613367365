import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useMutation, useFragment } from 'react-relay';
import { ListManagementQuery, NewsletterSubscriptionFilter } from './__generated__/ListManagementQuery.graphql';
import { ListManagementItem_item$key as itemFragment } from './__generated__/ListManagementItem_item.graphql';
import { ListManagementMutation, BulkUpdateOption } from './__generated__/ListManagementMutation.graphql';
import { OthramDate } from '../../OthramDate';
import { handleError, urlFor } from '../../Utils';

interface ItemProps {
  item: itemFragment,
};

const ListManagementItem = ({ item }: ItemProps) => {
  const {
    email, dateCreated, dateUnsubscribed, dateVerified
  } = useFragment<itemFragment>(
    graphql`
    fragment ListManagementItem_item on NewsletterSubscription {
        email
        dateCreated
        dateUnsubscribed
        dateVerified
      }
    `,
    item
  );
  return (
    <tr>
      <td>{email}</td>
      <td><OthramDate date={new Date(dateCreated)} /></td>
      <td>{dateVerified && <OthramDate date={new Date(dateVerified)} />}</td>
      <td>{dateUnsubscribed && <OthramDate date={new Date(dateUnsubscribed)} />}</td>
    </tr>
  );
};

const ListManagement = () => {
  const [search, setSearch] = useState<string | null | undefined>(null);
  const [filter, setFilter] = useState<NewsletterSubscriptionFilter>('VERIFIED');
  const [operation, setOperation] = useState<BulkUpdateOption>('ADD');
  const [bulkUpdateList, updateInFlight] = useMutation<ListManagementMutation>(
    graphql`
      mutation ListManagementMutation($emails: String!, $operation: BulkUpdateOption!, $force: Boolean) {
        bulkUpdateNewsletterSubscription(emails: $emails, operation: $operation, force: $force) {
          rowsChanged
        }
      }`);

  const {
    newsletterSubscriptions,
    totalListCount,
    totalVerifiedCount,
    totalUnsubscribedCount,
    totalUnverifiedCount
  } = useLazyLoadQuery<ListManagementQuery>(
    graphql`
    query ListManagementQuery($search: String, $filter:NewsletterSubscriptionFilter) {
      newsletterSubscriptions(first:100, search:$search, filterBy:$filter) {
        edges {
          node {
            ...ListManagementItem_item
          }
        }
        pageInfo {
          hasNextPage
        }
      }
      totalListCount: newsletterSubscriptionCount(filterBy:ALL)
      totalVerifiedCount: newsletterSubscriptionCount(filterBy:VERIFIED)
      totalUnsubscribedCount: newsletterSubscriptionCount(filterBy:UNSUBSCRIBED)
      totalUnverifiedCount: newsletterSubscriptionCount(filterBy:UNVERIFIED)
    }`, { filter, search });

  const edges = newsletterSubscriptions?.edges;

  const frmSearch = (evt: React.FormEvent<HTMLFormElement>) => {
    const frm = evt.target as HTMLFormElement;
    evt.preventDefault();

    const val = frm.search.value;
    setSearch(val.length === 0 ? null : val);
    return false;
  };

  const frmSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    const frm = evt.target as HTMLFormElement;
    evt.preventDefault();

    bulkUpdateList({
      variables: {
        operation,
        emails: frm.emails.value,
        force: operation === 'ADD' ? !frm.verify_first.checked : null
      },
      onCompleted ({ bulkUpdateNewsletterSubscription }, errs) {
        if (!errs) {
          alert(`${bulkUpdateNewsletterSubscription?.rowsChanged} Rows modified`);
          document.location.reload(); // i cant trigger requery right now
        }
      },
      onError: handleError
    });

    return false;
  };

  const rows = (edges && edges.length > 0)
    ? edges.map((value, key) => {
      const node = value?.node;
      return node && <ListManagementItem item={node} key={key} />;
    })
    : [(<tr key='none'><td colSpan={6}>No emails yet. Share the feed!</td></tr>)];

  return (
    <div>
      <div>
        <form className='mb-3 border-bottom pb-4' onSubmit={frmSearch}>
          <div className='input-group mt-4'>
            <input id='article-search-criteria' className='form-control ' name='search' type='text' defaultValue={search || undefined} />
            <div className='input-group-append'>
              <input type='submit' className='btn btn-dark-blue' value='Search' />
            </div>
          </div>
        </form>
        <div className='form-check form-check-inline'>
          <input className='form-check-input' type='radio' name='filterBy' id='verifiedRadio' value='VERIFIED'
            checked={filter === 'VERIFIED'} onChange={setFilter.bind(this, 'VERIFIED')} />
          <label className='form-check-label' htmlFor='verifiedRadio'>Verified ({totalVerifiedCount})</label>
        </div>
        <div className='form-check form-check-inline'>
          <input className='form-check-input' type='radio' name='filterBy' id='unsubscribedRadio' value='UNSUBSCRIBED'
            checked={filter === 'UNSUBSCRIBED'} onChange={setFilter.bind(this, 'UNSUBSCRIBED')} />
          <label className='form-check-label' htmlFor='unsubscribedRadio'>Unsubscribed ({totalUnsubscribedCount})</label>
        </div>
        <div className='form-check form-check-inline mb-3'>
          <input className='form-check-input' type='radio' name='filterBy' id='unverifiedRadio' value='UNVERIFIED'
            checked={filter === 'UNVERIFIED'} onChange={setFilter.bind(this, 'UNVERIFIED')} />
          <label className='form-check-label' htmlFor='unverifiedRadio'>Unverified ({totalUnverifiedCount})</label>
        </div>
        <div className='form-check form-check-inline mb-3'>
          <input className='form-check-input' type='radio' name='filterBy' id='allRadio' value='ALL'
            checked={filter === 'ALL'} onChange={setFilter.bind(this, 'ALL')} />
          <label className='form-check-label' htmlFor='allRadio'>All ({totalListCount})</label>
        </div>

      </div>
      <Table striped hover bordered className='mb-5'>
        <thead>
          <tr>
            <th>Email</th>
            <th>Created</th>
            <th>Verified</th>
            <th>Unsubscribed</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
      <form onSubmit={frmSubmit} className='mb-3'>
        <fieldset>
          <legend>Bulk Operations</legend>
          <div>
            <div className='form-check form-check-inline mb-2'>
              <input className='form-check-input' type='radio' name='inlineRadioOptions' id='addRadio' value='ADD'
                checked={operation === 'ADD'} onChange={setOperation.bind(this, 'ADD')} />
              <label className='form-check-label' htmlFor='addRadio'>Subscribe</label>
            </div>
            <div className='form-check form-check-inline'>
              <input className='form-check-input' type='radio' name='inlineRadioOptions' id='removeRadio' value='REMOVE'
                checked={operation === 'REMOVE'} onChange={setOperation.bind(this, 'REMOVE')} />
              <label className='form-check-label' htmlFor='removeRadio'>Unsubscribe</label>
            </div>
          </div>
          <div className='form-group'>
            <label htmlFor='emails'>Emails</label>
            <textarea className='form-control' name='emails' placeholder='1 email per line' required></textarea>
          </div>
          {operation === 'ADD' && (<div className='form-check'>
            <input className='form-check-input' name='verify_first' type='checkbox' value='true' id='forceOp' />
            <label className='form-check-label' htmlFor='forceOp'>
              Require verification
            </label>
          </div>)}
          {updateInFlight
            ? (<h1>Saving...</h1>)
            : (
              <button
                type='submit'
                className='btn btn-primary mt-3'
              >{operation === 'ADD' ? 'Add to List' : 'Remove from list'}</button>
            )}
        </fieldset>
      </form>
      <a href={urlFor('articles.export_newsletter_list')} target='_blank' rel='noreferrer'>Download entire email list</a>
    </div>
  );
};

export default ListManagement;
