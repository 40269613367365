/**
 * @generated SignedSource<<73c9b91313dfc2484f6f989ac030c7c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CaseUpdateMutation$variables = {
  description?: string | null;
  id?: string | null;
  namusId?: string | null;
  othramCaseNumber?: string | null;
  title?: string | null;
};
export type CaseUpdateMutation$data = {
  readonly caseUpdate: {
    readonly case: {
      readonly description: string | null;
      readonly id: string;
      readonly namusId: string | null;
      readonly othramCaseNumber: string | null;
      readonly title: string | null;
    } | null;
  } | null;
};
export type CaseUpdateMutation = {
  response: CaseUpdateMutation$data;
  variables: CaseUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "namusId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "othramCaseNumber"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "title"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "namusId",
        "variableName": "namusId"
      },
      {
        "kind": "Variable",
        "name": "othramCaseNumber",
        "variableName": "othramCaseNumber"
      },
      {
        "kind": "Variable",
        "name": "title",
        "variableName": "title"
      }
    ],
    "concreteType": "CaseUpdate",
    "kind": "LinkedField",
    "name": "caseUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Case",
        "kind": "LinkedField",
        "name": "case",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "namusId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "othramCaseNumber",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CaseUpdateMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "CaseUpdateMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "9a07bdf9406d4b19d3f8adabfe4c00ca",
    "id": null,
    "metadata": {},
    "name": "CaseUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation CaseUpdateMutation(\n  $id: ID\n  $title: String\n  $description: String\n  $othramCaseNumber: String\n  $namusId: String\n) {\n  caseUpdate(id: $id, title: $title, description: $description, othramCaseNumber: $othramCaseNumber, namusId: $namusId) {\n    case {\n      id\n      title\n      description\n      namusId\n      othramCaseNumber\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "81e94e15fe6d8bc7de489465316b225f";

export default node;
