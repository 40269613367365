import React from 'react';
import ReactDom from 'react-dom';
import ScrollingLogo from './ScrollingLogo';
import { Dropdown } from 'react-bootstrap';
import { urlFor } from '../Utils';

type HeaderProps = {
  userAuthenticated: boolean,
  requestPath: string,
  userGreeting: string,
  canEditOwn: boolean,
  canViewAdmin: boolean,
  canViewMatching: boolean,
  contentManager: boolean,
  hasWoodpeckerDatabase: boolean,
  hasMatchesDatabase: boolean,
  imageLink: string,
  showContribute: boolean,
  enableArticles: boolean
};

const Header = ({
  userAuthenticated,
  requestPath,
  userGreeting,
  canEditOwn,
  canViewAdmin,
  canViewMatching,
  contentManager,
  hasWoodpeckerDatabase,
  hasMatchesDatabase,
  imageLink,
  showContribute,
  enableArticles
}: HeaderProps) => {
  return (
    <div className='row px-3 position-absolute w-100'>
      <nav className='nav bg-white navbar-expand-lg' id='mainNav'>
        <div className='d-flex flex-row flex-fill'>
          <a id='navbar-logo' className='navbar-brand flex-fill mr-0 align-self-center' href={urlFor(userAuthenticated ? 'base.dashboard' : 'base.home_page')}>
            <ScrollingLogo imageLink={imageLink} />
          </a>
          <button className='navbar-toggler mr-sm-2' type='button' data-toggle='collapse' onClick={() => { document.getElementById('burger')?.classList.toggle('isActive'); }} data-target='#navbarNav' aria-controls='navbarNav' aria-expanded='false' aria-label='Toggle navigation'>
            <div className='menu_burger' id='burger' data-target='#navbarNav'>
              <div className='burger_bar' data-target='#navbarNav'></div>
              <div className='burger_bar' data-target='#navbarNav'></div>
              <div className='burger_bar' data-target='#navbarNav'></div>
            </div>
          </button>
        </div>
        <div className='collapse navbar-collapse text-center' id='navbarNav'>
          <ul className='navbar-nav flex-fill'>
            <div className='d-flex nav-section mx-auto mb-2 mb-lg-0'>
              {enableArticles && (<><li className='nav-item'>
                <Dropdown as='span' style={{ cursor: 'pointer' }}>
                  <Dropdown.Toggle
                    className={`nav-link ${requestPath.includes('articles') ? 'active-link' : ''}`}
                    variant=''
                    as='a'
                    style={{ textDecoration: 'none' }}
                  >
                    CASES
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href={urlFor('articles.all_articles')}>Browse</Dropdown.Item>
                    <Dropdown.Item href={urlFor('articles.beta_news_feed')}>Updates</Dropdown.Item>
                    <Dropdown.Item href={urlFor('geos.states')}>Map (USA)</Dropdown.Item>
                    <Dropdown.Item href={urlFor('geos.provinces')}>Map (Canada)</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li className='nav-item'>
                <a className={`nav-link ${requestPath.includes('faq') ? 'active-link' : ''}`} href={urlFor('support.faq')}>FAQ</a>
              </li>
              <li className='nav-item'>
                <a className={`nav-link ${requestPath.includes('how-it-works') ? 'active-link' : ''}`} href={urlFor('base.home_page', { _anchor: 'how-it-works' })}>HOW IT WORKS</a>
              </li></>)}
              {!userAuthenticated &&
                <li className='nav-item d-block d-lg-none'>
                  <a className={`nav-link login-link ${requestPath.includes('login') ? 'active-link' : ''}`} href={urlFor('user.login')}>LOGIN</a>
                </li>
              }
            </div>
            {userAuthenticated
              ? (
                <div className='d-flex nav-section'>
                  <li className='nav-item'>
                    <a className='nav-link flex-fill' href={urlFor('base.dashboard')}>Dashboard</a>
                  </li>
                  <li className='nav-item'>
                    <Dropdown as='span' style={{ cursor: 'pointer' }}>
                      <Dropdown.Toggle className='nav-link flex-fill' id='navbarToolsDropdown' variant='' as='a' style={{ textDecoration: 'none' }}>{userGreeting}</Dropdown.Toggle>
                      <Dropdown.Menu>
                        {canEditOwn &&
                          <>
                            <Dropdown.Item href={urlFor('user.update_profile')}>Update account</Dropdown.Item>
                            <Dropdown.Item href={urlFor('user.change_password')}>Change password</Dropdown.Item>
                          </>
                        }
                        <Dropdown.Item href={urlFor('user.logout')}>Logout</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                  {(canViewAdmin || canViewMatching || contentManager) &&
                    <li className='nav-item'>
                      <Dropdown as='span' style={{ cursor: 'pointer' }}>
                        <Dropdown.Toggle id='navbarToolsDropdown' className='nav-link flex-fill' variant='' as='a' style={{ textDecoration: 'none' }}>Tools</Dropdown.Toggle>
                        <Dropdown.Menu>
                          {enableArticles && contentManager &&
                            <>
                              <Dropdown.Item href={urlFor('articles.content_dashboard')}>Edit Articles</Dropdown.Item>
                              <Dropdown.Divider />
                              <Dropdown.Item href={urlFor('relay.relay_admin_route', { component: 'ContentBlocks' })}>Edit Content Blocks</Dropdown.Item>
                              <Dropdown.Divider />
                              <Dropdown.Item href={urlFor('relay.email_newsletter')}>Email Newsletter</Dropdown.Item>
                              <Dropdown.Divider />
                              <Dropdown.Item href={urlFor('relay.agencies')}>Agency Admin</Dropdown.Item>
                              {canViewAdmin && <Dropdown.Divider />}
                            </>
                          }
                          {canViewAdmin &&
                            <>
                              <Dropdown.Item href={urlFor('admin.dashboard')}>Admin</Dropdown.Item>
                              {canViewMatching && <Dropdown.Divider />}
                            </>
                          }
                          {canViewMatching &&
                            <>
                              {hasWoodpeckerDatabase && <Dropdown.Item href={urlFor('woodpecker.search')}>Woodpecker</Dropdown.Item>}
                              <Dropdown.Item href={urlFor('match.search')}>Search</Dropdown.Item>
                              {hasMatchesDatabase && <Dropdown.Item href={urlFor('match.all')}>DNA Matches</Dropdown.Item>}
                              <Dropdown.Divider />
                              <div className='py-1 px-4'>
                                <form id='nav-query-form' action={urlFor('match.search')} className='form-inline'>
                                  <div className='input-group input-group-sm w-100' style={{ minWidth: 200 }}>
                                    <input id='nav-bar-search-criteria' className='form-control fs-mask' name='query' type='text' placeholder='kit, name, or email' />
                                    <div className='input-group-append'>
                                      <input type='submit' id='menu-submit' className='btn btn-dark-blue btn-lg form-control-lg' value='Search' />
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </>
                          }
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                  }
                </div>
              )
              : (
                <a href={urlFor('user.register')} className='btn d-lg-none mb-2 contribute-dna d-sm-block btn-primary'>
                  <span style={{ verticalAlign: 'middle' }}>Contribute DNA</span>
                </a>
              )
            }
          </ul>
        </div>
        {!userAuthenticated &&
          <div className='flex-fill d-lg-flex flex-row d-none'>
            <div className='flex-fill'></div>
            <a href={urlFor('user.login')} className='btn mb-lg-0 mb-2 login-link mr-2 btn-outline-dark-blue'>
              <span style={{ verticalAlign: 'middle' }}>
                Login
              </span>
            </a>
            {showContribute &&
              <a href={urlFor('user.register')} className='btn mb-lg-0 mb-2 contribute-dna mr-2 float-right btn-primary'>
                <span style={{ verticalAlign: 'middle' }}>
                  Contribute DNA
                </span>
              </a>
            }
          </div>
        }
      </nav>
    </div>
  );
};

(window as any).loadHeader = function (domElement: HTMLElement, props: HeaderProps) {
  ReactDom.render(<Header {...props} />, domElement);
};
