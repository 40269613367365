/**
 * @generated SignedSource<<1bbf319f91fa297a7807cb1729078a71>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NewsletterSignupModalMutation$variables = {
  email: string;
  recaptcha: string;
};
export type NewsletterSignupModalMutation$data = {
  readonly signupEmailNewsletter: {
    readonly result: boolean | null;
  } | null;
};
export type NewsletterSignupModalMutation = {
  response: NewsletterSignupModalMutation$data;
  variables: NewsletterSignupModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "recaptcha"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "recaptcha",
        "variableName": "recaptcha"
      }
    ],
    "concreteType": "SignupEmailNewsletter",
    "kind": "LinkedField",
    "name": "signupEmailNewsletter",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewsletterSignupModalMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewsletterSignupModalMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "95ca7ba47349cefa4fca4dddd860bbc2",
    "id": null,
    "metadata": {},
    "name": "NewsletterSignupModalMutation",
    "operationKind": "mutation",
    "text": "mutation NewsletterSignupModalMutation(\n  $email: String!\n  $recaptcha: String!\n) {\n  signupEmailNewsletter(email: $email, recaptcha: $recaptcha) {\n    result\n  }\n}\n"
  }
};
})();

(node as any).hash = "dac1c68a3a8e7290a802cb11dbb6902c";

export default node;
