/**
 * @generated SignedSource<<bc5a8080ae80390821211a353cb0b42a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ListManagementItem_item$data = {
  readonly dateCreated: any;
  readonly dateUnsubscribed: any | null;
  readonly dateVerified: any | null;
  readonly email: string;
  readonly " $fragmentType": "ListManagementItem_item";
};
export type ListManagementItem_item$key = {
  readonly " $data"?: ListManagementItem_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"ListManagementItem_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ListManagementItem_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateCreated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateUnsubscribed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateVerified",
      "storageKey": null
    }
  ],
  "type": "NewsletterSubscription",
  "abstractKey": null
};

(node as any).hash = "e8743dc333dacb7103ee78170099e165";

export default node;
