import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useMutation } from 'react-relay';
import { AgencyManagementModalQuery } from './__generated__/AgencyManagementModalQuery.graphql';
import { AgencyManagementModalDeleteMutation } from './__generated__/AgencyManagementModalDeleteMutation.graphql';
import { AgencyManagementModalUpdateMutation, AgencyManagementModalUpdateMutation$variables } from './__generated__/AgencyManagementModalUpdateMutation.graphql';
import { handleError, variablesFromForm, PayloadError, useErrors } from '../../Utils';
import { useBoolean } from 'usehooks-ts';

const { Suspense } = React;

interface ModalItemProps {
  id: string,
  setModalId: Function
};

const FormWebsiteInput = ({
  name,
  label,
  defaultValue,
  clearErrorHelper,
  FormErrorControl
}: {
  name:string,
  label:string,
  defaultValue?:string,
  FormErrorControl: Function,
  clearErrorHelper: Function
}) => {
  return (<Form.Group className='mb-3' controlId={name}>
    <Form.Label>{label}</Form.Label>
    <Form.Control size='sm' type='text' placeholder='https://' defaultValue={defaultValue} onChange={clearErrorHelper(name)} />
    {FormErrorControl(name)}
  </Form.Group>);
};

const AgencyManagementModal = (props: ModalItemProps) => {
  const newRecord = props.id === 'new';
  const { value: show, setValue: setShow } = useBoolean(true);
  const { setError, clearErrorHelper, FormErrorControl } = useErrors();
  const errorHelpers = { clearErrorHelper, FormErrorControl };
  const closeModal = () => setShow.bind(this, false) && props.setModalId(null);
  const { value: isEditing, setValue: setEditing } = useBoolean(newRecord);
  const id = (newRecord ? undefined : props.id) as string;

  const data = !newRecord && useLazyLoadQuery<AgencyManagementModalQuery>(
    graphql`
            query AgencyManagementModalQuery($id: ID!) {
              agency: node(id: $id){
                ... on Agency {
                  id
                  name
                  description
                  wikipediaUrl
                  instagramUrl
                  facebookUrl
                  twitterUrl
                  websiteUrl
              }
              }
            }
            `, { id: id }
  );
  const agency = data ? data?.agency : null;

  const name = agency?.name || undefined;
  const description = agency?.description || undefined;
  const wikipediaUrl = agency?.wikipediaUrl || undefined;
  const instagramUrl = agency?.instagramUrl || undefined;
  const facebookUrl = agency?.facebookUrl || undefined;
  const twitterUrl = agency?.twitterUrl || undefined;
  const websiteUrl = agency?.websiteUrl || undefined;
  const nullableFields = ['description', 'wikipediaUrl', 'instagramUrl', 'facebookUrl', 'twitterUrl', 'websiteUrl'];

  const [agencyUpdate, updateInFlight] = useMutation<AgencyManagementModalUpdateMutation>(
    graphql`
          mutation AgencyManagementModalUpdateMutation(
            $id: ID, 
            $name: String, 
            $description: String, 
            $websiteUrl: String, 
            $twitterUrl: String, 
            $facebookUrl: String,
            $instagramUrl: String,
            $wikipediaUrl: String,
            $nullFields: [AgencyUpdateNullColumnOption]
          ) {
            agencyUpdate(
              id: $id, 
              name: $name, 
              description: $description, 
              websiteUrl: $websiteUrl, 
              twitterUrl: $twitterUrl, 
              facebookUrl: $facebookUrl,
              instagramUrl: $instagramUrl,
              wikipediaUrl: $wikipediaUrl,
              nullFields: $nullFields
            ) {
              agency{
                id
                name
                description
                wikipediaUrl
                instagramUrl
                facebookUrl
                twitterUrl
                websiteUrl
                ...AgencyManagementItem_item
              }
            }
          }
          `);

  const [softDelete, deleteInFlight] = useMutation<AgencyManagementModalDeleteMutation>(
    graphql`
                  mutation AgencyManagementModalDeleteMutation($id: ID!) {
                    softDelete(id: $id) {
                      success
                  }
              }
                  `);

  const inFlight = updateInFlight || deleteInFlight;

  const btnDelete = () => {
    id && softDelete({
      variables: {
        id
      },
      onCompleted (_, errs) {
        if (!errs) {
          document.location.reload();
        } else {
          handleError(errs[0]);
        }
      },
      onError: handleError
    });

    return false;
  };

  const formRef = React.createRef() as React.RefObject<HTMLFormElement>;

  const btnSave = (evt: React.SyntheticEvent) => {
    evt.preventDefault();
    const form = formRef.current as HTMLFormElement;

    const variables = variablesFromForm(form, nullableFields, { agencyName: 'name' }, { id }) as AgencyManagementModalUpdateMutation$variables;

    agencyUpdate({
      variables,
      onCompleted (_, errs) {
        if (!errs) {
          return document.location.reload();
        }
        const err = errs[0] as PayloadError;

        if (err?.extensions?.field || err?.extensions?.fields) {
          (err?.extensions?.fields || [err?.extensions?.field]).forEach((field) => {
            setError(field as string, err.message);
          });
        } else {
          handleError();
        }
      },
      onError: handleError
    });

    return false;
  };

  const loading = (
    <dl>
      <dt>Website</dt>
      <dd><a href='#'>...</a></dd>
      <dt>Wikipedia</dt>
      <dd><a href='#'>...</a></dd>
      <dt>Instagram</dt>
      <dd><a href='#'>...</a></dd>
      <dt>Twitter</dt>
      <dd><a href='#'>...</a></dd>
      <dt>Facebook</dt>
      <dd><a href='#'>...</a></dd>
    </dl>
  );
  return (
    <Modal
      show={show}
      onHide={closeModal}
    >
      <Modal.Header>
        <Modal.Title>
          {isEditing ? 'Editing ' : null}{newRecord ? 'New Agency' : name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isEditing
          ? (<Form ref={formRef}>
            <Form.Group className='mb-3' controlId='agencyName'>
              <Form.Label>Name</Form.Label>
              <Form.Control size='lg' type='text' defaultValue={name} onChange={clearErrorHelper('name')} />
              {FormErrorControl('name')}
            </Form.Group>
            <Form.Group className='mb-3' controlId='description'>
              <Form.Label>Description</Form.Label>
              <Form.Control as='textarea' defaultValue={description} />
            </Form.Group>
            <FormWebsiteInput name='websiteUrl' defaultValue={websiteUrl} label='Website Url' {...errorHelpers}/>
            <FormWebsiteInput name='wikipediaUrl' defaultValue={wikipediaUrl} label='Wikipedia Url' {...errorHelpers}/>
            <FormWebsiteInput name='instagramUrl' defaultValue={instagramUrl} label='Instagram Url' {...errorHelpers}/>
            <FormWebsiteInput name='twitterUrl' defaultValue={twitterUrl} label='Twitter Url' {...errorHelpers}/>
            <FormWebsiteInput name='facebookUrl' defaultValue={facebookUrl} label='Facebook Url' {...errorHelpers}/>
          </Form>)
          : (<Suspense fallback={loading}>
            <p>{description || 'No description'}</p>
            <dl>
              {websiteUrl && (<><dt>Website</dt>
                <dd><a href={websiteUrl}>{websiteUrl}</a></dd>
              </>)}
              {wikipediaUrl && (<><dt>Wikipedia</dt>
                <dd><a href={wikipediaUrl}>{wikipediaUrl}</a></dd>
              </>)}
              {instagramUrl && (<><dt>Instagram</dt>
                <dd><a href={instagramUrl}>{instagramUrl}</a></dd>
              </>)}
              {twitterUrl && (<><dt>Twitter</dt>
                <dd><a href={twitterUrl}>{twitterUrl}</a></dd>
              </>)}
              {facebookUrl && (<><dt>Facebook</dt>
                <dd><a href={facebookUrl}>{facebookUrl}</a></dd>
              </>)}
            </dl>
          </Suspense>)}
      </Modal.Body>
      <Modal.Footer>
        {inFlight
          ? ('Loading')
          : (<>
            {!newRecord && (<Button variant='danger' onClick={btnDelete}>Delete</Button>)}
            {isEditing
              ? (<>
                <Button onClick={newRecord ? closeModal : setEditing.bind(this, false)} variant='secondary'>Cancel <i className='fa fa-ban'></i></Button>
                <Button onClick={btnSave}>Save <i className='fa fa-save'></i></Button>
              </>)
              : (<Button variant='primary' onClick={setEditing.bind(this, true)}>Edit</Button>)}
          </>)}
      </Modal.Footer>
    </Modal >
  );
};
(window as any).AgencyManagementModal = AgencyManagementModal;

export default AgencyManagementModal;
