import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useMutation } from 'react-relay';
import { CaseAgencyModalQuery } from './__generated__/CaseAgencyModalQuery.graphql';
import { CaseAgencyModalDeleteMutation } from './__generated__/CaseAgencyModalDeleteMutation.graphql';
import { CaseAgencyModalUpdateMutation, CaseAgencyModalUpdateMutation$variables } from './__generated__/CaseAgencyModalUpdateMutation.graphql';
import { handleError, variablesFromForm } from '../../Utils';
import { useBoolean } from 'usehooks-ts';

interface ModalItemProps {
  id?: string,
  caseId: string,
  setModalId: Function
};

const CaseAgencyModal = ({ id, caseId, setModalId }: ModalItemProps) => {
  const newRecord = id === 'new';
  const { value: show, setFalse: hideShow } = useBoolean(true);
  const closeModal = () => { hideShow(); setModalId(null); };
  id = (newRecord ? undefined : id) as string;

  const data = useLazyLoadQuery<CaseAgencyModalQuery>(
    graphql`
            query CaseAgencyModalQuery($id: ID!, $hasId: Boolean!) {
              agencies {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              caseAgency: node(id: $id) @include(if: $hasId){
                ... on CaseAgency {
                  id
                  agency {
                    id
                    name
                  }
                  caseNumber
                  tipline
                  agentInCharge
                  primary
                  display
                }
              }
            }
            `, { id: id || '', hasId: !newRecord }
  );
  const agencies = data?.agencies?.edges || [];
  const caseAgency = data?.caseAgency;

  const caseNumber = caseAgency?.caseNumber || undefined;
  const tipline = caseAgency?.tipline || undefined;
  const agentInCharge = caseAgency?.agentInCharge || undefined;
  const primary = caseAgency?.primary || undefined;
  const display = caseAgency?.display || undefined;
  const agency = caseAgency?.agency || undefined;
  const nullableFields = ['tipline', 'agentInCharge', 'caseNumber'];

  const agencyOptions = agencies.map((edge) => {
    const agency = edge?.node;
    const agencyId = agency?.id;
    return (<option value={agencyId} key={agencyId}>{agency?.name}</option>);
  });

  const [caseAgencyUpdate, updateInFlight] = useMutation<CaseAgencyModalUpdateMutation>(
    graphql`
          mutation CaseAgencyModalUpdateMutation(
            $id: ID, 
            $caseId: ID, 
            $agencyId: ID, 
            $caseNumber: String, 
            $tipline: String, 
            $agentInCharge: String, 
            $primary: Boolean, 
            $display: Boolean,
            $nullFields: [CaseAgencyUpdateNullColumnOption]
            ) {
            caseAgencyUpdate(
              id: $id, 
              caseId: $caseId, 
              agencyId: $agencyId, 
              caseNumber: $caseNumber, 
              tipline: $tipline, 
              agentInCharge: $agentInCharge, 
              primary: $primary,
              display: $display,
              nullFields: $nullFields
              ) {
              caseAgency{
                id
                caseNumber
                tipline
                agentInCharge
                primary
                display
                ...CaseAgencyItem_caseAgency
              }
            }
          }
          `);

  const [softDelete, deleteInFlight] = useMutation<CaseAgencyModalDeleteMutation>(
    graphql`
                  mutation CaseAgencyModalDeleteMutation($id: ID!) {
                    softDelete(id: $id) {
                      success
                  }
              }
                  `);

  const inFlight = updateInFlight || deleteInFlight;

  const btnDelete = () => {
    id && softDelete({
      variables: {
        id
      },
      onCompleted (_, errs) {
        if (!errs) {
          document.location.reload();
        } else {
          handleError(errs[0]);
        }
      },
      onError: handleError
    });

    return false;
  };

  const formRef = React.createRef() as React.RefObject<HTMLFormElement>;

  const frmSubmit = (evt: React.SyntheticEvent) => {
    evt.preventDefault();
    const form = formRef.current as HTMLFormElement;

    const variables = variablesFromForm(form, nullableFields, { agencyName: 'name' }, { caseId, id }) as unknown as CaseAgencyModalUpdateMutation$variables;

    caseAgencyUpdate({
      variables,
      onCompleted (_, errs) {
        if (!errs) {
          document.location.reload();
        } else {
          handleError(errs[0]);
        }
      },
      onError: handleError
    });

    return false;
  };

  return (
    <Modal
      show={show}
      onHide={closeModal}
    >
      <Modal.Header>
        <Modal.Title>
          Editing {newRecord ? 'New Agency' : agency?.name} Link
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form ref={formRef}>
          <Form.Group className='mb-3' controlId='agencyId'>
            <Form.Label>Agency</Form.Label><br />
            <Form.Control as='select' className='form-control' size='lg' defaultValue={agency?.id}>
              {agencyOptions}
            </Form.Control>
          </Form.Group>
          <Form.Group className='mb-3' controlId='caseNumber'>
            <Form.Label>Agency Case Number</Form.Label>
            <Form.Control size='lg' type='text' defaultValue={caseNumber} />
          </Form.Group>
          <Form.Group className='mb-3' controlId='tipline'>
            <Form.Label>Tipline</Form.Label>
            <Form.Control size='lg' type='text' defaultValue={tipline} />
          </Form.Group>
          <Form.Group className='mb-3' controlId='agentInCharge'>
            <Form.Label>Agent in Change</Form.Label>
            <Form.Control size='lg' type='text' defaultValue={agentInCharge} />
          </Form.Group>
          <Form.Group className='mb-3' controlId='primary'>
            <Form.Check type='checkbox' label='Is primary agency for case?' defaultChecked={primary} />
          </Form.Group>
          <Form.Group className='mb-3' controlId='display'>
            <Form.Check type='checkbox' label='Display on case page?' defaultChecked={display} />
          </Form.Group>

        </Form>
      </Modal.Body>
      <Modal.Footer>
        {inFlight
          ? ('Loading')
          : (<>
            {!newRecord && (<Button variant='danger' onClick={btnDelete}>Delete</Button>)}
            <Button onClick={closeModal} variant='secondary'>Cancel <i className='fa fa-ban'></i></Button>
            <Button onClick={frmSubmit}>Save <i className='fa fa-save'></i></Button>
          </>)}
      </Modal.Footer>
    </Modal >
  );
};
(window as any).CaseAgencyModal = CaseAgencyModal;

export default CaseAgencyModal;
