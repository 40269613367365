import React, { useRef, useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';
import { InlineNewsletterSignupMutation } from './__generated__/InlineNewsletterSignupMutation.graphql';
import ReCAPTCHA from 'react-google-recaptcha';

interface InlineNewsletterSignupProps {
  recaptchaSiteKey: string | null;
}
const InlineNewsletterSignup = ({ recaptchaSiteKey }: InlineNewsletterSignupProps) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const [step, setStep] = useState<number>(0);
  const reset = setStep.bind(null, 0);
  const emailError = step === -1;
  const setEmailError = setStep.bind(null, -1);
  const signedUp = step === 1;
  const hidden = step === 2;
  const setHidden = setStep.bind(null, 2);
  const setSignedUp = () => setTimeout(setHidden, 3000) && setStep(1);

  const [signup, signupInFlight] = useMutation<InlineNewsletterSignupMutation>(
    graphql`
      mutation InlineNewsletterSignupMutation($email: String!, $recaptcha: String!) {
        signupEmailNewsletter(email: $email, recaptcha: $recaptcha) {
          result
        }
      }
    `);

  const frmSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    const frm = evt.target as HTMLFormElement;
    evt.preventDefault();

    let recaptcha = '';
    if (recaptchaRef.current) {
      recaptcha = await recaptchaRef.current?.executeAsync() ?? '';
      if (!recaptcha) return;
    }

    signup({
      variables: {
        email: frm.email.value,
        recaptcha
      },
      onCompleted (_, errs) {
        (errs ? setEmailError : setSignedUp)();
      }
    });

    return false;
  };
  if (hidden) {
    return null;
  }

  return (signedUp
    ? (<h5>Signup complete. Please check your email</h5>)
    : (<form onSubmit={frmSubmit} className='newsletter form-inline col-md-8 col-sm-12 mx-auto'>
      {recaptchaSiteKey && <ReCAPTCHA
        ref={recaptchaRef}
        size='invisible'
        sitekey={recaptchaSiteKey}
      />}
      <div className='col-12 col-md-10 col-lg-8 px-0 mx-auto mb-4 text-center'>
        <p>Enter your email below to get the latest case updates direct to your inbox.</p>
        <div className='input-group'>
          <input className='form-control rounded-sm-screen' onChange={emailError ? reset : undefined} name='email' type='email' placeholder='email' required />
          <div className='input-group-append bg-white d-none d-md-block'>
            {signupInFlight && <i className='fa fa-circle-o-notch'></i>}
            <input type='submit' disabled={signupInFlight} id='article-submit' className='btn btn-outline-dark-blue rounded-right h-100' value='Subscribe' />
          </div>
        </div>
        <input type='submit' disabled={signupInFlight} id='article-submit-mobile' className='btn btn-outline-dark-blue d-md-none rounded-right mt-3 w-100' value='Subscribe' />
        {emailError && <div className='invalid-feedback'>
          Email invalid. Please check the format
        </div>}
      </div >
    </form >));
};

(window as any).InlineNewsletterSignup = InlineNewsletterSignup;

export default InlineNewsletterSignup;
