/**
 * @generated SignedSource<<f9373d1501d77836eb7ff859f078cc00>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CaseAgencyModalQuery$variables = {
  hasId: boolean;
  id: string;
};
export type CaseAgencyModalQuery$data = {
  readonly agencies: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
      } | null;
    } | null>;
  } | null;
  readonly caseAgency?: {
    readonly agency?: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly agentInCharge?: string | null;
    readonly caseNumber?: string | null;
    readonly display?: boolean;
    readonly id?: string;
    readonly primary?: boolean;
    readonly tipline?: string | null;
  } | null;
};
export type CaseAgencyModalQuery = {
  response: CaseAgencyModalQuery$data;
  variables: CaseAgencyModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "AgencyConnection",
  "kind": "LinkedField",
  "name": "agencies",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AgencyEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Agency",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Agency",
  "kind": "LinkedField",
  "name": "agency",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "caseNumber",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tipline",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "agentInCharge",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "primary",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "display",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CaseAgencyModalQuery",
    "selections": [
      (v4/*: any*/),
      {
        "condition": "hasId",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "caseAgency",
            "args": (v5/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "type": "CaseAgency",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CaseAgencyModalQuery",
    "selections": [
      (v4/*: any*/),
      {
        "condition": "hasId",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "caseAgency",
            "args": (v5/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "type": "CaseAgency",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "7380151a741482cfb85d883b9af16806",
    "id": null,
    "metadata": {},
    "name": "CaseAgencyModalQuery",
    "operationKind": "query",
    "text": "query CaseAgencyModalQuery(\n  $id: ID!\n  $hasId: Boolean!\n) {\n  agencies {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  caseAgency: node(id: $id) @include(if: $hasId) {\n    __typename\n    ... on CaseAgency {\n      id\n      agency {\n        id\n        name\n      }\n      caseNumber\n      tipline\n      agentInCharge\n      primary\n      display\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1d7cda53a0bbcfcf080c7516fd3e43d4";

export default node;
