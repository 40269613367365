import React, { useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useFragment } from 'react-relay';
import UnitedStatesSvg from './UnitedStatesSvg';
import { StatesQuery } from './__generated__/StatesQuery.graphql';
import { States_state$key as stateFragment } from './__generated__/States_state.graphql';
import { urlFor } from '../../Utils';

interface ItemProps {
  state: stateFragment,
};

const stateData = (props: ItemProps) => {
  const { state, name } = useFragment(
    graphql`
      fragment States_state on State {
        name
        state: iso3166
      }
    `,
    props.state
  );
  return { state, name };
};
interface StatesProps {
  pageTitle: string,
};
const States = ({ pageTitle }: StatesProps) => {
  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  const { usa } = useLazyLoadQuery<StatesQuery>(
    graphql`
      query StatesQuery {
        usa {
          name
          states {
            id
            name
            ...States_state
          }
        }
      }
      `, {}
  );

  const states = usa?.states || [];
  const stateRows = states.map((state) => {
    return state && stateData({ state });
  });

  return <>
    <div className='hero-content'>
      <Container className='text-dark page-title text-break text-center'>
        <h1>{pageTitle}</h1>
      </Container>
    </div>
    <div className='d-flex flex-column'>
      <Container className='px-3 py-3'>
        <div className='d-flex justify-content-center'>
          <UnitedStatesSvg />
        </div>
        <Container fluid className='bg-white border mt-5'>
          <h2 className='mt-3'>Cases by State</h2>
          <Row className='mb-3 d-none d-md-flex flex-column flex-wrap' id='state_rows'>
            {stateRows.map(row => (
              row && <div key={row.state}><a href={urlFor('geos.state', { state: row.state })} >{row.name}</a></div>
            ))}
          </Row>
          <select onChange={(event) => {
            if (event.target.value) {
              window.location.href = urlFor('geos.state', { state: event.target.value });
            }
          }}
          className='d-md-none form-control form-control-lg mb-3'
          >
            <option value={''}>Select a State</option>
            {stateRows.map(row => (
              row && <option key={row.state} value={row.state}>{row.name}</option>
            ))}
          </select>
        </Container>
      </Container>
    </div>
  </>;
};

(window as any).States = States;

export default States;
