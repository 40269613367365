import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import { RenderContentBlockQuery } from './__generated__/RenderContentBlockQuery.graphql';
import { RenderContentBlock_block$key as blockFragment } from './__generated__/RenderContentBlock_block.graphql';

interface RenderContentBlockProps {
  identifier?: string,
  block?: blockFragment,
  rotate?: boolean,
  defaultHtml?: string
};

const RenderContentBlock = (props: RenderContentBlockProps) => {
  let block = props.block;
  const identifier = props.identifier;
  if (block && identifier) {
    throw new Error('You cant pass both identifier and block');
  } else if (identifier) {
    const rotate = props.rotate || false;

    const query = useLazyLoadQuery<RenderContentBlockQuery>(
      graphql`
            query RenderContentBlockQuery($identifier: String!, $rotate: Boolean!) {
              block: contentBlock(identifier: $identifier, rotate: $rotate){
                ...RenderContentBlock_block
              }
          }
            `, { identifier, rotate }
    );
    block = query?.block || undefined;
  } else if (props.rotate) {
    throw new Error('You cant pass rotate when passing block');
  }

  const contentBlock = block && useFragment<blockFragment>(
    graphql`
      fragment RenderContentBlock_block on ContentBlock {
        id
        content
      }
    `,
    block
  );
  const id = contentBlock?.id;
  const content = contentBlock?.content || '';
  if (id) {
    return (<div className='content_block' data-id={id} dangerouslySetInnerHTML={{ __html: content }} />);
  } else if (props.defaultHtml) {
    return (<div dangerouslySetInnerHTML={{ __html: props.defaultHtml }} />);
  } else {
    return null;
  }
};
(window as any).RenderContentBlock = RenderContentBlock;

export default RenderContentBlock;
