/**
 * @generated SignedSource<<901bda64e80f04655165298fb33e8ef6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewsletterSubscriptionFilter = "ALL" | "UNSUBSCRIBED" | "UNVERIFIED" | "VERIFIED" | "%future added value";
export type ListManagementQuery$variables = {
  filter?: NewsletterSubscriptionFilter | null;
  search?: string | null;
};
export type ListManagementQuery$data = {
  readonly newsletterSubscriptions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"ListManagementItem_item">;
      } | null;
    } | null>;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    };
  } | null;
  readonly totalListCount: number | null;
  readonly totalUnsubscribedCount: number | null;
  readonly totalUnverifiedCount: number | null;
  readonly totalVerifiedCount: number | null;
};
export type ListManagementQuery = {
  response: ListManagementQuery$data;
  variables: ListManagementQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v2 = [
  {
    "kind": "Variable",
    "name": "filterBy",
    "variableName": "filter"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": "totalListCount",
  "args": [
    {
      "kind": "Literal",
      "name": "filterBy",
      "value": "ALL"
    }
  ],
  "kind": "ScalarField",
  "name": "newsletterSubscriptionCount",
  "storageKey": "newsletterSubscriptionCount(filterBy:\"ALL\")"
},
v5 = {
  "alias": "totalVerifiedCount",
  "args": [
    {
      "kind": "Literal",
      "name": "filterBy",
      "value": "VERIFIED"
    }
  ],
  "kind": "ScalarField",
  "name": "newsletterSubscriptionCount",
  "storageKey": "newsletterSubscriptionCount(filterBy:\"VERIFIED\")"
},
v6 = {
  "alias": "totalUnsubscribedCount",
  "args": [
    {
      "kind": "Literal",
      "name": "filterBy",
      "value": "UNSUBSCRIBED"
    }
  ],
  "kind": "ScalarField",
  "name": "newsletterSubscriptionCount",
  "storageKey": "newsletterSubscriptionCount(filterBy:\"UNSUBSCRIBED\")"
},
v7 = {
  "alias": "totalUnverifiedCount",
  "args": [
    {
      "kind": "Literal",
      "name": "filterBy",
      "value": "UNVERIFIED"
    }
  ],
  "kind": "ScalarField",
  "name": "newsletterSubscriptionCount",
  "storageKey": "newsletterSubscriptionCount(filterBy:\"UNVERIFIED\")"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ListManagementQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "NewsletterSubscriptionConnection",
        "kind": "LinkedField",
        "name": "newsletterSubscriptions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NewsletterSubscriptionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NewsletterSubscription",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ListManagementItem_item"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ListManagementQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "NewsletterSubscriptionConnection",
        "kind": "LinkedField",
        "name": "newsletterSubscriptions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NewsletterSubscriptionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NewsletterSubscription",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dateCreated",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dateUnsubscribed",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dateVerified",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ]
  },
  "params": {
    "cacheID": "2ad69ad06a5ac582f439b9262edf4786",
    "id": null,
    "metadata": {},
    "name": "ListManagementQuery",
    "operationKind": "query",
    "text": "query ListManagementQuery(\n  $search: String\n  $filter: NewsletterSubscriptionFilter\n) {\n  newsletterSubscriptions(first: 100, search: $search, filterBy: $filter) {\n    edges {\n      node {\n        ...ListManagementItem_item\n        id\n      }\n    }\n    pageInfo {\n      hasNextPage\n    }\n  }\n  totalListCount: newsletterSubscriptionCount(filterBy: ALL)\n  totalVerifiedCount: newsletterSubscriptionCount(filterBy: VERIFIED)\n  totalUnsubscribedCount: newsletterSubscriptionCount(filterBy: UNSUBSCRIBED)\n  totalUnverifiedCount: newsletterSubscriptionCount(filterBy: UNVERIFIED)\n}\n\nfragment ListManagementItem_item on NewsletterSubscription {\n  email\n  dateCreated\n  dateUnsubscribed\n  dateVerified\n}\n"
  }
};
})();

(node as any).hash = "42f909da6fa06d4a6bee903d46911bfb";

export default node;
