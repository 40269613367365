import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';
import { Container, Row, Col } from 'react-bootstrap';
import GeoHeader from './GeoHeader';
import WikiDataBox from './WikiDataBox';
import RenderContentBlock from './RenderContentBlock';
import UnitedStatesSvg from './UnitedStatesSvg';
import GeoCaseCards from './GeoCaseCards';
import { StateQuery } from './__generated__/StateQuery.graphql';

import { urlFor } from '../../Utils';

interface StateProps {
  iso3166: string,
};

const State = ({ iso3166 }: StateProps) => {
  const contentBlockIdentifier = `geo_us_${iso3166}`;
  const { usa, contentBlock } = useLazyLoadQuery<StateQuery>(
    graphql`
        query StateQuery($iso3166: String!, $contentBlockIdentifier: String!) {
          usa {
            state(iso3166: $iso3166) {
              id
              name
              ...WikiDataBox_geo
              ...GeoHeader_geo
              ...GeoNewsFeed_geo
              ...GeoCaseCards_geo
            }
          }
          contentBlock(identifier: $contentBlockIdentifier){
            ...RenderContentBlock_block
          }
        }
      `,
    { iso3166, contentBlockIdentifier }
  );

  const state = usa?.state;
  const flagImgUrl = urlFor('static', { filename: `img/state_flags/${iso3166}.svg` });

  return (state && <>
    <GeoHeader geo={state} />
    <div className='d-flex flex-column'>
      <Container className='px-3 py-3 border bg-white mb-3'>
        <Row className='flex-lg-row mx-0 mb-3'>
          <Col>
            <UnitedStatesSvg iso3166={iso3166} />
          </Col>
          <Col className='d-flex flex-column m-auto' md={4}>
            <Col>
              {flagImgUrl && (<img src={flagImgUrl} alt={state.name} width='100%' />)}
            </Col>
            <Col className='m-1'>
              <WikiDataBox geo={state} />
              {contentBlock && <RenderContentBlock block={contentBlock} />}
            </Col>
          </Col>
        </Row>
        <GeoCaseCards geo={state} /><br />
        <a href={urlFor('geos.states')} className='btn btn-sm btn-outline-primary mt-2 ml-3'>&laquo; Back to map</a><br />
      </Container>
    </div>
  </>);
};

(window as any).State = State;

export default State;
