import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useBoolean, useTimeout, useLocalStorage, useStep } from 'usehooks-ts';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, PaymentIntent } from '@stripe/stripe-js';
import { ContributionThanksBody } from './ContributionThanks';
import Step1 from './ContributeStep1';
import Step2 from './ContributeStep2';
import { stripePublicKey, hideDelay, popupAfter, retryEvery, dataLayer, urlFor } from '../../Utils';
const stripePromise = loadStripe(stripePublicKey);

interface ContributeModalProps {
  initialValue: number,
  buckets: number[],
  caseId: string,
  onlySubscription: boolean,
  slug:string,
  initialFullName:string,
  initialEmail:string,
  minimumContribution: number,
  maximumContribution?: number,
}

const ContributeModal = ({
  initialValue,
  buckets,
  initialEmail = '',
  initialFullName = '',
  caseId = '',
  slug = '',
  minimumContribution,
  maximumContribution,
  onlySubscription = false
}: ContributeModalProps) => {
  const { value: visible, setTrue: show, setFalse: hide } = useBoolean(false);
  const [currentStep, { goToNextStep, goToPrevStep }] = useStep(3);
  const [contributeLastShown, setContributeLastShown] = useLocalStorage('contributeLastShown', 0);
  const now = Date.now();
  const { value: subscription, setTrue: setSubscription, setFalse: setContributionOrig } = useBoolean(onlySubscription);
  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent>();
  const gotoStep2 = (paymentIntent: PaymentIntent) => { setPaymentIntent(paymentIntent); goToNextStep(); };
  const [cents, setCents] = useState<number>(initialValue);
  const showPopup = () => { if (!(window as any).popupShowing) { (window as any).popupShowing = true; show(); } };
  const hidePopup = () => { (window as any).popupShowing = false; hide(); };
  const setContribution = () => {
    if (maximumContribution && cents > maximumContribution) {
      setCents(maximumContribution);
    }
    setContributionOrig();
  };
  useTimeout(showPopup, ((now - Number(contributeLastShown)) > retryEvery) ? popupAfter : null);
  useTimeout(hidePopup, (currentStep === 3) ? hideDelay : null);

  const campaignParams = { utm_term: slug, utm_campaign: 'Donate Popup', utm_medium: 'popup', utm_source: 'website' };

  useEffect(() => {
    switch (currentStep) {
    case 1:
      dataLayer.push({
        event: 'Pageview',
        pagePath: (subscription
          ? urlFor('articles.set_subscription_amount', { amount: cents, ...campaignParams })
          : urlFor('articles.contribute', { slug, amount: cents, ...campaignParams })),
        pageTitle: subscription ? 'Subscribe to DNASolves' : 'Contribute to a Case'
      });
      break;
    case 2:
      dataLayer.push({
        event: 'Pageview',
        pagePath: (subscription
          ? urlFor('articles.subscribe', campaignParams)
          : urlFor('articles.billing', { slug, ...campaignParams })),
        pageTitle: subscription ? 'Make a Monthly Contribution' : 'Make a Contribution'
      });
      break;
    case 3:
      dataLayer.push({
        event: 'Pageview',
        pagePath: ((subscription || !slug)
          ? urlFor('base.home_page', { show_contribution_thanks: true, ...campaignParams })
          : urlFor('articles.successful_contribution', { slug, ...campaignParams })),
        pageTitle: document.title
      });
      break;
    }
  }, [currentStep]);

  let stepContainer;
  switch (currentStep) {
  case 1:
    stepContainer = (<Step1 {...{
      minimumContribution,
      maximumContribution,
      initialValue,
      buckets,
      caseId,
      gotoStep2,
      subscription,
      setSubscription,
      setContribution,
      setCents,
      cents,
      onlySubscription,
      hide
    }}/>);
    break;
  case 2:
    stepContainer = (<Elements stripe={stripePromise} options={{ clientSecret: paymentIntent?.client_secret || '' }}>
      <Step2 {...{
        goToNextStep,
        cents,
        paymentIntent,
        subscription,
        slug,
        initialEmail,
        initialFullName,
        back: goToPrevStep
      }} />
    </Elements>
    );
    break;
  case 3:
    stepContainer = (<ContributionThanksBody/>);
    break;
  }

  return (
    <Modal onHide={hidePopup} onShow={setContributeLastShown.bind(null, now)} show={visible} fullscreen='sm-down'>
      <Modal.Header closeButton/>
      {stepContainer}
    </Modal >
  );
};

(window as any).ContributeModal = ContributeModal;

export default ContributeModal;
