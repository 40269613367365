import React, { MouseEventHandler } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { Modal, Button, Form, Container, Row, Col } from 'react-bootstrap';
import { useBoolean } from 'usehooks-ts';
import { useMutation } from 'react-relay';
import { ContributeStep1Mutation } from './__generated__/ContributeStep1Mutation.graphql';
import { handleError } from '../../Utils';
import { PaymentIntent } from '@stripe/stripe-js';

interface Step1Props {
  buckets: number[],
  caseId: string,
  subscription: boolean,
  gotoStep2: Function,
  setCents: Function,
  setContribution: MouseEventHandler<HTMLElement>,
  setSubscription: MouseEventHandler<HTMLElement>,
  cents: number,
  onlySubscription: boolean,
  minimumContribution:number,
  maximumContribution?:number
}
const Step1 = ({
  buckets,
  caseId,
  gotoStep2,
  subscription,
  setSubscription,
  setContribution,
  cents, setCents,
  onlySubscription,
  minimumContribution,
  maximumContribution
}: Step1Props) => {
  const setAmount: React.ChangeEventHandler<HTMLInputElement> = (evt: React.ChangeEvent<HTMLInputElement>) => {
    let newCents = Math.floor(Number(evt.target.value) * 100);
    if (newCents < minimumContribution) {
      newCents = minimumContribution;
    } else if (!subscription && maximumContribution && newCents > maximumContribution) {
      newCents = maximumContribution;
    }
    return setCents(newCents);
  };
  const amount = (cents / 100).toFixed(2);
  const minimumContributionAmount = (minimumContribution / 100).toFixed(2);
  const maximumContributionAmount = (!subscription && maximumContribution) ? (maximumContribution / 100).toFixed(2) : undefined;
  const { value: otherSelected, setTrue: setCustom, setFalse: setPreset } = useBoolean(false);
  const fullyFund = !subscription && maximumContribution && (cents >= maximumContribution);

  let amountValid = true;
  let invalidAmountMessage;
  if (cents < minimumContribution) {
    amountValid = false;
    invalidAmountMessage = `You must donate more than $${minimumContributionAmount}`;
  } else if (!subscription && maximumContribution && (cents > maximumContribution)) {
    amountValid = false;
    invalidAmountMessage = `You can't donate more than $${maximumContributionAmount}`;
  }

  const [createPaymentIntent, createIntentInFlight] = useMutation<ContributeStep1Mutation>(
    graphql`
      mutation ContributeStep1Mutation($amount: Int!, $subscription: Boolean!, $case: ID!) {
        createPaymentIntent(amount: $amount, subscription: $subscription, caseId: $case) {
          paymentIntentId
          clientSecret
        }
      }
    `);

  const createIntentClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    createPaymentIntent({
      variables: {
        subscription,
        case: caseId,
        amount: cents
      },
      onCompleted ({ createPaymentIntent }, errs) {
        if (!errs) {
          gotoStep2({
            id: createPaymentIntent?.paymentIntentId,
            client_secret: createPaymentIntent?.clientSecret
          } as PaymentIntent);
        }
      },
      onError: handleError
    });
  };
  const contributionMsg = `Set${subscription ? ' monthly' : ''} contribution:`;
  return (
    <Modal.Body>
      <Container className='mx-0 p-0'>
        <Row>
          <Col className='mx-auto'>
            <Form id='contribution-form'>
              <Row>
                <Col className='text-center my-3 mx-0 p-0'>
                  <h4 style={{ fontFamily: 'Viga', fontSize: '1.75rem', lineHeight: '2rem' }}>
                  Help families get the <br/>answers they deserve...
                  </h4>
                </Col>
              </Row>
              {subscription
                ? (<>
                  <Row>
                    <Col className='text-center mx-auto p-0'>
                      <p>
                      Your contributions help pay for reagents, lab supplies, and research tools that
                      directly help solve crimes and connect missing &amp; unidentified people back to their families.
                      </p>
                    </Col>
                  </Row>
                </>)
                : (
                  <Row>
                    <Col className='text-center mx-auto p-0'>
                      <p>
                      DNAsolves relies on donations from people like you to solve cases nobody else can.
                      Every donation helps a family get the closure they deserve.
                      </p>
                    </Col>
                  </Row>
                )}

              <div id='preset-row' className='d-flex flex-column mt-1'>
                {contributionMsg}
                <Container id='preset_contribution_btns' className='d-flex flex-wrap p-0 btn-group mx-auto' role='group'>
                  {buckets.map((bucket) => {
                    const bucketAmount = bucket / 100;
                    const onClick = () => { setCents(bucket); setPreset(); };
                    const active = !otherSelected && (bucket === cents);
                    return (<Button variant='primary'
                      style={{ borderRadius: '.25rem', opacity: active ? '1' : '0.4' }}
                      onClick={onClick}
                      key={bucket}
                      className={`mt-1 mt-md-0 mr-1 ${active ? 'active' : ''}`}>${bucketAmount}</Button>);
                  })}
                  <Button variant='primary'
                    style={{ borderRadius: '.25rem', opacity: otherSelected ? '1' : '0.4' }}
                    id='other_amount_btn' onClick={setCustom}
                    className={`mt-1 mr-1 mt-md-0 ${otherSelected ? 'active' : ''}`}>Other</Button>
                </Container>
              </div>
              <Container id='contribution_amount' className={`${otherSelected ? 'd-flex' : 'd-none'} flex-row flex-fill mt-3`}>
                <h1 className='text-center my-auto pr-4'>$</h1>
                <Form.Control
                  id='amount' onChange={setAmount}
                  min={minimumContributionAmount}
                  max={maximumContributionAmount}
                  inputMode='decimal' name='amount'
                  pattern='\d+\.\d{2}' placeholder='0.00'
                  type='number'
                  value={amount}/>
              </Container>
              <Form.Text className={`invalid-feedback ${amountValid ? '' : 'd-block'}`}>
                {invalidAmountMessage}
              </Form.Text>
              {!onlySubscription && (<Container id='payment_type_btn' className=' p-0 btn-group mt-4 mx-auto' role='group'>
                <Button id='one-time-enable' variant='primary' onClick={setContribution} data-description-box='one_time_desc' data-payment-type='ONE_TIME' className={`w-50 ${subscription ? '' : 'active'}`}>Once</Button>
                <Button id='subscription-enable' variant='primary' onClick={setSubscription} data-description-box='subscription_desc' data-payment-type='SUBSCRIBE' className={`mr-1 w-50 ${subscription ? 'active' : ''}`}>Monthly</Button>
              </Container>)}
              {subscription
                ? (
                  <div id='subscription_desc' className='description-card mt-2'>
                    <div className='card-body'>
                      <ul className='m-0'>
                        <li>You will be charged once a month, cancel anytime</li>
                        <li>You can see what cases were funded with your contribution</li>
                        <li>Contributions will be evenly divided between all unfunded cases</li>
                      </ul>
                    </div>
                  </div>
                )
                : (
                  <div id='one_time_desc' className='description-card mt-2'>
                    <div className='card-body'>
                      <ul className='m-0'>
                        <li className={`${fullyFund ? '' : 'd-none'} fully-funded-msg`}><b>This will completely fund the case</b></li>
                        <li>You will only be charged once</li>
                        <li>Your contribution will go towards this case</li>
                      </ul>
                    </div>
                  </div>
                )}
              <Form.Row>
                <Button
                  variant='dark-blue'
                  className='w-100 mt-2 mb-2'
                  disabled={!amountValid || createIntentInFlight}
                  onClick={createIntentClick}
                  id='submit'
                  name='submit'
                  type='submit'>
                  {createIntentInFlight ? 'Processing' : `Contribute ${subscription ? 'Monthly' : 'Once'}`}
                </Button>
              </Form.Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </Modal.Body >);
};

export default Step1;
