/**
 * @generated SignedSource<<d00abc8fccd3c1e88e5b2e45f07fe6f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AgencyManagementItem_item$data = {
  readonly facebookUrl: string | null;
  readonly id: string;
  readonly instagramUrl: string | null;
  readonly name: string;
  readonly twitterUrl: string | null;
  readonly websiteUrl: string | null;
  readonly wikipediaUrl: string | null;
  readonly " $fragmentType": "AgencyManagementItem_item";
};
export type AgencyManagementItem_item$key = {
  readonly " $data"?: AgencyManagementItem_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"AgencyManagementItem_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AgencyManagementItem_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wikipediaUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "instagramUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "facebookUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "twitterUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "websiteUrl",
      "storageKey": null
    }
  ],
  "type": "Agency",
  "abstractKey": null
};

(node as any).hash = "04a6ea7473d96bbb3993533a554a0090";

export default node;
