import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import fetchGraphQL from './FetchGraphQl';

const fetchRelay = async function (params, variables) {
  return fetchGraphQL(params.text, variables);
};

export default new Environment({
  network: Network.create(fetchRelay),
  store: new Store(new RecordSource())
});
