/**
 * @generated SignedSource<<84764a01f04868d6aa5d5802f60c2f03>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GeoHeader_geo$data = {
  readonly name: string;
  readonly pageBannerImgUrl: string | null;
  readonly " $fragmentType": "GeoHeader_geo";
};
export type GeoHeader_geo$key = {
  readonly " $data"?: GeoHeader_geo$data;
  readonly " $fragmentSpreads": FragmentRefs<"GeoHeader_geo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GeoHeader_geo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pageBannerImgUrl",
      "storageKey": null
    }
  ],
  "type": "State",
  "abstractKey": null
};

(node as any).hash = "feab4b9db1af436013fe1f4372ecae04";

export default node;
