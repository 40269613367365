import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useFragment } from 'react-relay';
import CanadianProvincesSvg from './CanadianProvincesSvg';
import { ProvincesQuery } from './__generated__/ProvincesQuery.graphql';
import { Provinces_province$key as provinceFragment } from './__generated__/Provinces_province.graphql';
import { urlFor } from '../../Utils';

interface ItemProps {
  province: provinceFragment,
};

const provinceData = (props: ItemProps) => {
  return useFragment(
    graphql`
      fragment Provinces_province on State {
        name
        province: iso3166
      }
    `,
    props.province
  );
};

interface ProvincesProps {
  pageTitle: string,
};

const Provinces = ({ pageTitle }: ProvincesProps) => {
  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  const { canada } = useLazyLoadQuery<ProvincesQuery>(
    graphql`
      query ProvincesQuery {
        canada {
          name
          provinces: states {
            id
            ...Provinces_province
          }
        }
      }
      `, {}
  );

  const provinces = canada?.provinces || [];
  const provinceRows = provinces.map((province) => {
    return (province && provinceData({ province }));
  });

  return <>
    <div className='hero-content'>
      <Container className='text-dark page-title text-break text-center'>
        <h1>{pageTitle}</h1>
      </Container>
    </div>
    <div className='d-flex flex-column'>
      <Container className='px-3 pb-3'>
        <div className='d-flex justify-content-center'>
          <CanadianProvincesSvg />
        </div>
        <Container fluid className='bg-white border'>
          <h2 className='mt-3'>Cases by Province</h2>
          <Row className='mb-3 d-none d-md-flex'>
            {provinceRows.map(row => (
              row && <Col md={4} sm={6} xs={12}><a href={urlFor('geos.province', { province: row.province })} >{row.name}</a></Col>
            ))}
          </Row>
          <select onChange={(event) => {
            if (event.target.value) {
              window.location.href = urlFor('geos.province', { province: event.target.value });
            }
          }}
          className='d-md-none form-control form-control-lg mb-3'
          >
            <option value={''}>Select a Province</option>
            {provinceRows.map(row => (
              row && <option key={row.province} value={row.province}>{row.name}</option>
            ))}
          </select>
        </Container>
      </Container>
    </div>
  </>;
};

(window as any).Provinces = Provinces;

export default Provinces;
