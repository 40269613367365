/**
 * @generated SignedSource<<47117d3fa7dd50d85c0c00d7af29a6a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CaseAgencyItem_caseAgency$data = {
  readonly agency: {
    readonly name: string;
  } | null;
  readonly caseNumber: string | null;
  readonly display: boolean;
  readonly id: string;
  readonly primary: boolean;
  readonly " $fragmentType": "CaseAgencyItem_caseAgency";
};
export type CaseAgencyItem_caseAgency$key = {
  readonly " $data"?: CaseAgencyItem_caseAgency$data;
  readonly " $fragmentSpreads": FragmentRefs<"CaseAgencyItem_caseAgency">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CaseAgencyItem_caseAgency",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Agency",
      "kind": "LinkedField",
      "name": "agency",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "caseNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "primary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "display",
      "storageKey": null
    }
  ],
  "type": "CaseAgency",
  "abstractKey": null
};

(node as any).hash = "2d58b118ccbb15a698705845548ad6dd";

export default node;
