import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';

import { WikiDataBox_geo$key as geoFragment } from './__generated__/WikiDataBox_geo.graphql';

interface WikiDataBoxProps {
  geo: geoFragment,
};

const WikiDataBox = ({ geo }: WikiDataBoxProps) => {
  const { population, area } = useFragment(
    graphql`
      fragment WikiDataBox_geo on State {
        population
        area
      }
    `,
    geo
  );
  return (<>
    {(population || area) && <dl className='mt-3 d-flex flex-row'>
      {population && <div className='mr-3'><dt>Population:</dt>
        <dd>{population.toLocaleString()}</dd></div>}
      {area && <div><dt>Area:</dt>
        <dd>{area.toLocaleString()} m<sup>2</sup></dd></div>}
    </dl>}
  </>
  );
};

export default WikiDataBox;
