import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useFragment } from 'react-relay';
import { CaseArticlesQuery } from './__generated__/CaseArticlesQuery.graphql';
import { CaseArticles_case$key as caseFragment } from './__generated__/CaseArticles_case.graphql';

interface ItemProps {
  case: caseFragment,
};
const CaseArticleItem = (props: ItemProps) => {
  const { articleStatus, description } = useFragment(
    graphql`
      fragment CaseArticles_case on CaseArticle {
        articleStatus
        description
      }
    `,
    props.case
  );

  return (
    <div>
      <i>
        {articleStatus}
      </i> - {description}
    </div>
  );
};

const itemFromCase = ({ node }) => {
  const { id, title } = node;
  return (<div key={id}>
    <h1>{title}</h1>
    <CaseArticleItem case={node} />
  </div>);
};

const CaseArticles = () => {
  const { caseArticles: { edges } } = useLazyLoadQuery<CaseArticlesQuery>(
    graphql`
      query CaseArticlesQuery {
        caseArticles {
          edges {
            node { 
              id
              title
              ...CaseArticles_case
            }
          }
        }
      }
      `, {}
  );

  const articleRows = edges.map(itemFromCase);

  return <>
    <div>{articleRows}</div>
  </>;
};

(window as any).CaseArticles = CaseArticles;

export default CaseArticles;
