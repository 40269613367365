import React from 'react';
import statesJson from './UsStatesSvg.json';
import { urlFor } from '../../Utils';

const stateData: { [name: string]: { path: string, name: string } } = statesJson.states;

interface Props {
  iso3166?: string,
};

const UnitedStatesSvg = ({ iso3166 }: Props) => {
  const stateRows = Object.keys(stateData).map((state) => {
    const activeClass = state === iso3166 ? ' active' : '';
    return (<a xlinkHref={urlFor('geos.state', { state })} key={state} target='_top'>
      <title>{`${stateData[state].name} Cases`}</title>
      <path id={state} className={`st2${activeClass}`} d={stateData[state].path}></path>
    </a>);
  });

  return <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' style={{ maxWidth: '750px' }} viewBox='0 0 959 593' width='100%'>
    <style type='text/css'>
      {`
        .st2{fill:#bbbbd0;}
        .enable-hover .st2:hover {
          fill:#6c6bb7;
        }
        .active {
          fill:#323174;
        }
        .enable-hover .st2:hover, .active {
          stroke-width:2px;
          stroke-linejoin: round;
          cursor: pointer;
        }
      `}
    </style>
    <g id='outlines' className='enable-hover'>
      {stateRows}
    </g>
    <path id='frames' fill='none' stroke='#A9A9A9' strokeWidth='2' d='M215,493v55l36,45 M0,425h147l68,68h85l54,54v46'></path>
  </svg>;
};

export default UnitedStatesSvg;
