import React, { useEffect } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';
import { Container } from 'react-bootstrap';

import { GeoHeader_geo$key as geoFragment } from './__generated__/GeoHeader_geo.graphql';

interface GeoHeaderProps {
  geo: geoFragment,
};

const GeoHeader = ({ geo }: GeoHeaderProps) => {
  const { pageBannerImgUrl, name } = useFragment(
    graphql`
      fragment GeoHeader_geo on State {
        name
        pageBannerImgUrl
      }
    `,
    geo
  );

  const pageTitle = `${name} Cases`;

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return (
    <div className='hero-content hero ' style={{ backgroundImage: `url('${pageBannerImgUrl?.replace('http', 'https')}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
      <Container className='text-dark page-title pt-0 text-break text-center'>
        <h1 className='my-auto' style={{ mixBlendMode: 'difference', color: 'white', textShadow: '1px 3px 3px black' }}>{pageTitle}</h1>
      </Container>
    </div>
  );
};

export default GeoHeader;
