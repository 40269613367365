/**
 * @generated SignedSource<<00d3bedd53a7224be9d9c58606ea20ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BulkUpdateOption = "ADD" | "REMOVE" | "%future added value";
export type ListManagementMutation$variables = {
  emails: string;
  force?: boolean | null;
  operation: BulkUpdateOption;
};
export type ListManagementMutation$data = {
  readonly bulkUpdateNewsletterSubscription: {
    readonly rowsChanged: number | null;
  } | null;
};
export type ListManagementMutation = {
  response: ListManagementMutation$data;
  variables: ListManagementMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "emails"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "force"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "operation"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "emails",
        "variableName": "emails"
      },
      {
        "kind": "Variable",
        "name": "force",
        "variableName": "force"
      },
      {
        "kind": "Variable",
        "name": "operation",
        "variableName": "operation"
      }
    ],
    "concreteType": "BulkUpdateNewsletterSubscription",
    "kind": "LinkedField",
    "name": "bulkUpdateNewsletterSubscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rowsChanged",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ListManagementMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ListManagementMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "0e430dc853703cc7e293dc2c478ced7d",
    "id": null,
    "metadata": {},
    "name": "ListManagementMutation",
    "operationKind": "mutation",
    "text": "mutation ListManagementMutation(\n  $emails: String!\n  $operation: BulkUpdateOption!\n  $force: Boolean\n) {\n  bulkUpdateNewsletterSubscription(emails: $emails, operation: $operation, force: $force) {\n    rowsChanged\n  }\n}\n"
  }
};
})();

(node as any).hash = "eb0cf98bae94c7602026117aaf315ebe";

export default node;
