import React, { useState, useEffect } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';
import { RegisterUserMutation } from './__generated__/RegisterUserMutation.graphql';
import { Container, Row } from 'react-bootstrap';
import { handleError, urlFor, PayloadError } from '../../Utils';

interface RegisterProps {
  nextUrl: string,
  pageTitle: string,
};

const Register = ({ nextUrl, pageTitle }: RegisterProps) => {
  const [passwordError, setPasswordError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  const [registerUser, registering] = useMutation<RegisterUserMutation>(
    graphql`
      mutation RegisterUserMutation($email: String!, $password: String!) {
        createUser(email: $email, password: $password) {
            userId
            nextUrl
        }
      }
    `);
  const frmSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    const frm = evt.target as HTMLFormElement;
    evt.preventDefault();

    registerUser({
      variables: {
        email: frm.email.value,
        password: frm.password.value
      },
      onCompleted ({ createUser }, errs) {
        if (errs) {
          const err = errs[0] as PayloadError;
          if (err.extensions.field === 'email') {
            setEmailError(err.message);
          } else {
            setPasswordError(err.message);
          }
        } else {
          document.location = createUser?.nextUrl || urlFor('base.dashboard');
        }
      },
      onError: handleError
    });

    return false;
  };
  const resetPassword = () => passwordError && setPasswordError('');
  const resetEmail = () => emailError && setEmailError('');

  return (<Container className='px-3 py-3 '>
    <Row className='row my-md-3'>
      <div className='col-lg-8 mx-auto'>
        <ul className='nav nav-tabs' role='tablist'>
          <li className='nav-item '>
            <a className='nav-link active bg-white' data-toggle='tab' href='#register_tab' role='tab'>Sign up</a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' href={urlFor('user.login')}>Log in</a>
          </li>
        </ul>
        <div className='tab-content bg-white border border-top-0'>
          <div className='tab-pane active' id='register_tab'>
            <div className='row'>
              <div className='col-md-8 mx-auto '>
                <form method='post' onSubmit={frmSubmit}>
                  <input id='next_url' name='next_url' type='hidden' value={nextUrl} />
                  <h5 className='py-2'>Create your account</h5>
                  <div>
                    <div className='form-row'>
                      <label htmlFor='email'>Email</label>
                      <input className={`fs-mask form-control ${emailError ? 'is-invalid' : ''}`} id='email' name='email' onChange={resetEmail} required type='email' defaultValue='' />
                      {emailError && (<div className='invalid-feedback'>{emailError}</div>)}
                    </div>
                    <div className='form-row mt-3'>
                      <label htmlFor='password'>Password</label>
                      <input className={`fs-mask form-control ${passwordError ? 'is-invalid' : ''}`} id='password' onChange={resetPassword} name='password' required type='password' defaultValue='' />
                      {passwordError && (<div className='invalid-feedback'>{passwordError}</div>)}
                    </div>
                    <div className='form-row mt-3'>
                      <input id='consent' required name='consent' type='checkbox' value='y' className='mt-2' />
                      <label className='col form-check-label d-inline' htmlFor='consent'>I have read and agree to the{' '}
                        <a className='text-dark-blue' rel='noreferrer' href={urlFor('support.terms_of_use')} target='_blank'>Terms of Use</a>{' '}
                        and <a className='text-dark-blue' href={urlFor('support.privacy')} rel='noreferrer' target='_blank'>Privacy Policy</a>.
                      </label>
                    </div>
                    <div className='form-row mt-3'>
                      <input disabled={registering} className='btn btn-submit w-100 btn-primary my-2 fs-mask btn-xl' id='submit' name='submit' type='submit' value='Register' />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className='col-md-2'></div>
          </div>
        </div>
      </div>
    </Row>
  </Container>);
};

(window as any).Register = Register;

export default Register;
