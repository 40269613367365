import React from 'react';
import './UrlFor';
import './UrlForRoutes';

const { Suspense } = React;

const loading = (<div className='d-flex flex-column'>
  <div className='container px-3 py-3 border bg-white text-center'><h1>Loading</h1></div>
</div>);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const App = ({ props: { props, children } }) => {
  return (
    <Suspense fallback={loading}>
      {children}
    </Suspense>
  );
};

export default App;
