import 'regenerator-runtime/runtime';

import React, { DOMElement } from 'react';
import ReactDom from 'react-dom';
import App from './App';
import { RelayEnvironmentProvider } from 'react-relay';
import Environment from './RelayEnvironment';

window.React = React;
(window as any).ReactDom = ReactDom;

const AppRoot = (props, children) => {
  return (
    <RelayEnvironmentProvider environment={Environment}>
      <App props={props}>{children}</App>
    </RelayEnvironmentProvider>
  );
};

(window as any).attachApp = function (domElement: DOMElement, props = {}, children = []) {
  if (!Array.isArray(children)) {
    children = [children];
  };
  ReactDom.render(<AppRoot props={props}>{children}</AppRoot>, domElement);
};

export default AppRoot;
