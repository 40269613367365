import React, { useState, useEffect } from 'react';
import PreviewEmailNewsletter from './PreviewEmailNewsletter';
import ListManagement from './ListManagement';

interface ItemProps {
  defaultNewsletterSubject: string,
  defaultTeaserLine: string,
  pageTitle: string,
  defaultNumFundingItems: number,
  defaultNumNewsItems: number
};

const EmailNewsletter = (props: ItemProps) => {
  const [previewState, setPreview] = useState<boolean>(document.location.hash.substring(0, 8) === '#preview');
  const previewClass = previewState ? 'active' : '';
  const listClass = previewState ? '' : 'active';

  useEffect(() => {
    document.title = props.pageTitle;
  }, [props.pageTitle]);

  return (<>
    <div className='hero-content'>
      <div className='container text-dark page-title text-break text-center pt-0'>
        <h1>{props.pageTitle}</h1>
      </div>
    </div>
    <div className='d-flex flex-column'>
      <div className='container px-3 py-3 border bg-white mb-5'>
        <div className='row'>
          <div className='col'>
            <ul className='nav nav-tabs'>
              <li className='nav-item'>
                <a className={`nav-link ${listClass}`} href='#list' onClick={setPreview.bind(this, false)}>List Management</a>
              </li>
              <li className='nav-item'>
                <a className={`nav-link ${previewClass}`} href='#preview' onClick={setPreview.bind(this, true)}>Send Preview</a>
              </li>
            </ul>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            {previewState ? <PreviewEmailNewsletter {...props} /> : <ListManagement />}
          </div>
        </div>
      </div>
    </div>
  </>);
};

(window as any).EmailNewsletter = EmailNewsletter;

export default EmailNewsletter;
