import React, { useEffect } from 'react';
import { Container, Row, Col, Table, Button } from 'react-bootstrap';
import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useFragment } from 'react-relay';
import { CasesQuery } from './__generated__/CasesQuery.graphql';
import { CasesItem_item$key as itemFragment } from './__generated__/CasesItem_item.graphql';
import { urlFor } from '../../Utils';

const NEW_CASE_ENABLED = false;

interface ItemProps {
  item: itemFragment
};

const CaseItem = ({ item }: ItemProps) => {
  const row = useFragment<itemFragment>(
    graphql`
    fragment CasesItem_item on Case {
      id
      namusId
      othramCaseNumber
      title
    }
    `,
    item
  );
  const id = row?.id;
  const title = row?.title;
  const othramCaseNumber = row?.othramCaseNumber;
  const namusId = row?.namusId;

  return (
    <tr>
      <td><a href={urlFor('relay.relay_admin_route', { component: 'Cases', id })}>{title}</a></td>
      <td>{othramCaseNumber}</td>
      <td>{namusId}</td>
    </tr >
  );
};

const Cases = () => {
  const pageTitle = 'Case Management';
  useEffect(() => {
    document.title = pageTitle;
  }, []);

  const data = useLazyLoadQuery<CasesQuery>(
    graphql`
        query CasesQuery {
          cases {
          edges {
          node {
          ...CasesItem_item
        }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
        `, {}
  );

  const cases = data.cases?.edges;
  const caseRows = (
    (cases && cases.length > 0)
      ? cases.map((row, idx) => {
        return (row?.node && <CaseItem item={row.node} key={idx} />);
      })
      : [(<tr key='none'><td colSpan={3}>No cases yet...</td></tr>)]
  );

  const newCase = () => {
    document.location = urlFor('relay.relay_admin_route', { component: 'Cases', id: 'new' });
    return false;
  };

  return (<>
    <div className='hero-content'>
      <Container className='text-dark page-title text-break text-center'>
        <h1>{pageTitle}</h1>
      </Container>
    </div>
    <div className='d-flex flex-column'>
      <Container className='px-3 py-3 border bg-white mb-5'>
        <Row>
          <Col>
            {NEW_CASE_ENABLED && (<Button onClick={newCase} variant='primary' className='mb-3'>New Case <i className='fa fa-plus'></i></Button>)}

            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Case Name</th>
                  <th className='col-2'>Othram Case Number</th>
                  <th className='col-2'>Namus Id</th>
                </tr>
              </thead>
              <tbody>
                {caseRows}
              </tbody>
            </Table>
          </Col>
        </Row>

      </Container>
    </div>
  </>);
};

(window as any).Cases = Cases;

export default Cases;
