/**
 * @generated SignedSource<<45eaa451c6dd5e2ef6d311cb02bfb904>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AgencyUpdateNullColumnOption = "DESCRIPTION" | "FACEBOOK_URL" | "INSTAGRAM_URL" | "TWITTER_URL" | "WEBSITE_URL" | "WIKIPEDIA_URL" | "%future added value";
export type AgencyManagementModalUpdateMutation$variables = {
  description?: string | null;
  facebookUrl?: string | null;
  id?: string | null;
  instagramUrl?: string | null;
  name?: string | null;
  nullFields?: ReadonlyArray<AgencyUpdateNullColumnOption | null> | null;
  twitterUrl?: string | null;
  websiteUrl?: string | null;
  wikipediaUrl?: string | null;
};
export type AgencyManagementModalUpdateMutation$data = {
  readonly agencyUpdate: {
    readonly agency: {
      readonly description: string | null;
      readonly facebookUrl: string | null;
      readonly id: string;
      readonly instagramUrl: string | null;
      readonly name: string;
      readonly twitterUrl: string | null;
      readonly websiteUrl: string | null;
      readonly wikipediaUrl: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"AgencyManagementItem_item">;
    } | null;
  } | null;
};
export type AgencyManagementModalUpdateMutation = {
  response: AgencyManagementModalUpdateMutation$data;
  variables: AgencyManagementModalUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "facebookUrl"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "instagramUrl"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nullFields"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "twitterUrl"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "websiteUrl"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "wikipediaUrl"
},
v9 = [
  {
    "kind": "Variable",
    "name": "description",
    "variableName": "description"
  },
  {
    "kind": "Variable",
    "name": "facebookUrl",
    "variableName": "facebookUrl"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "instagramUrl",
    "variableName": "instagramUrl"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name"
  },
  {
    "kind": "Variable",
    "name": "nullFields",
    "variableName": "nullFields"
  },
  {
    "kind": "Variable",
    "name": "twitterUrl",
    "variableName": "twitterUrl"
  },
  {
    "kind": "Variable",
    "name": "websiteUrl",
    "variableName": "websiteUrl"
  },
  {
    "kind": "Variable",
    "name": "wikipediaUrl",
    "variableName": "wikipediaUrl"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wikipediaUrl",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "instagramUrl",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "facebookUrl",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "twitterUrl",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "websiteUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AgencyManagementModalUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "AgencyUpdate",
        "kind": "LinkedField",
        "name": "agencyUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Agency",
            "kind": "LinkedField",
            "name": "agency",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AgencyManagementItem_item"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v7/*: any*/),
      (v6/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v8/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "AgencyManagementModalUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "AgencyUpdate",
        "kind": "LinkedField",
        "name": "agencyUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Agency",
            "kind": "LinkedField",
            "name": "agency",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7fffed03979feb6f2ab0b473f6a4ec06",
    "id": null,
    "metadata": {},
    "name": "AgencyManagementModalUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation AgencyManagementModalUpdateMutation(\n  $id: ID\n  $name: String\n  $description: String\n  $websiteUrl: String\n  $twitterUrl: String\n  $facebookUrl: String\n  $instagramUrl: String\n  $wikipediaUrl: String\n  $nullFields: [AgencyUpdateNullColumnOption]\n) {\n  agencyUpdate(id: $id, name: $name, description: $description, websiteUrl: $websiteUrl, twitterUrl: $twitterUrl, facebookUrl: $facebookUrl, instagramUrl: $instagramUrl, wikipediaUrl: $wikipediaUrl, nullFields: $nullFields) {\n    agency {\n      id\n      name\n      description\n      wikipediaUrl\n      instagramUrl\n      facebookUrl\n      twitterUrl\n      websiteUrl\n      ...AgencyManagementItem_item\n    }\n  }\n}\n\nfragment AgencyManagementItem_item on Agency {\n  id\n  name\n  wikipediaUrl\n  instagramUrl\n  facebookUrl\n  twitterUrl\n  websiteUrl\n}\n"
  }
};
})();

(node as any).hash = "6af12bc6a3e2288743b0d4d8e0176cc3";

export default node;
