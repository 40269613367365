import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';

import { GeoCaseCards_geo$key as geoFragment } from './__generated__/GeoCaseCards_geo.graphql';
import { GeoCaseCards_item$key as itemFragment } from './__generated__/GeoCaseCards_item.graphql';

interface CaseItemProps {
  item: itemFragment,
};

const CaseItem = (props: CaseItemProps) => {
  const { caseCard } = useFragment<itemFragment>(
    graphql`
      fragment GeoCaseCards_item on CaseArticle {
        caseCard {
          rendered
        }
      }
    `,
    props.item
  );
  const rendered = caseCard?.rendered || '';

  return (
    <div className='article flex-fill mx-auto col-md-4 pl-lg-2 p-0 d-flex flex-column my-2' dangerouslySetInnerHTML={{ __html: rendered }} />
  );
};

interface GeoCaseCardsProps {
  geo: geoFragment,
};

const GeoCaseCards = ({ geo }: GeoCaseCardsProps) => {
  const { caseArticles, name } = useFragment<geoFragment>(
    graphql`
      fragment GeoCaseCards_geo on State {
        name
        caseArticles(sortByMagic: true){
          edges {
            node {
              ...GeoCaseCards_item
            }
          }
        }
      }
    `,
    geo
  );

  const edges = caseArticles?.edges || [];

  const caseRows = edges.map((edge, idx) => {
    const node = edge?.node;
    return (node && <CaseItem item={node} key={idx} />);
  });

  if (caseRows.length > 0) {
    return (
      <div>
        <h5>Cases in {name}:</h5>
        <div className='d-flex row flex-column flex-md-row mx-0 mx-md-2'>
          {caseRows}
        </div>
      </div>
    );
  } else {
    return null;
  };
};

export default GeoCaseCards;
