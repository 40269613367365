/**
 * @generated SignedSource<<d559df91b2a8f7c74866ea1e4b982b97>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CaseIndividualUpdateNullColumnOption = "BIRTHDATE" | "DATE_OF_DEATH" | "FAMILY_NAME" | "FULL_NAME" | "GIVEN_NAME" | "MISSING_FROM" | "MISSING_SINCE" | "NICKNAME" | "%future added value";
export type CaseIndividualModalUpdateMutation$variables = {
  birthdate?: any | null;
  caseId?: string | null;
  dateOfDeath?: any | null;
  display?: boolean | null;
  familyName?: string | null;
  fullName?: string | null;
  givenName?: string | null;
  id?: string | null;
  missingFrom?: string | null;
  missingSince?: any | null;
  nickname?: string | null;
  nullFields?: ReadonlyArray<CaseIndividualUpdateNullColumnOption | null> | null;
  primary?: boolean | null;
};
export type CaseIndividualModalUpdateMutation$data = {
  readonly caseIndividualUpdate: {
    readonly caseIndividual: {
      readonly birthdate: string | null;
      readonly dateOfDeath: string | null;
      readonly display: boolean;
      readonly familyName: string | null;
      readonly fullName: string | null;
      readonly givenName: string | null;
      readonly id: string;
      readonly missingFrom: string | null;
      readonly missingSince: string | null;
      readonly nickname: string | null;
      readonly primary: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"CaseIndividualItem_individual">;
    } | null;
  } | null;
};
export type CaseIndividualModalUpdateMutation = {
  response: CaseIndividualModalUpdateMutation$data;
  variables: CaseIndividualModalUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "birthdate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "caseId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dateOfDeath"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "display"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "familyName"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fullName"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "givenName"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "missingFrom"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "missingSince"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nickname"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nullFields"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "primary"
},
v13 = [
  {
    "kind": "Variable",
    "name": "birthdate",
    "variableName": "birthdate"
  },
  {
    "kind": "Variable",
    "name": "caseId",
    "variableName": "caseId"
  },
  {
    "kind": "Variable",
    "name": "dateOfDeath",
    "variableName": "dateOfDeath"
  },
  {
    "kind": "Variable",
    "name": "display",
    "variableName": "display"
  },
  {
    "kind": "Variable",
    "name": "familyName",
    "variableName": "familyName"
  },
  {
    "kind": "Variable",
    "name": "fullName",
    "variableName": "fullName"
  },
  {
    "kind": "Variable",
    "name": "givenName",
    "variableName": "givenName"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "missingFrom",
    "variableName": "missingFrom"
  },
  {
    "kind": "Variable",
    "name": "missingSince",
    "variableName": "missingSince"
  },
  {
    "kind": "Variable",
    "name": "nickname",
    "variableName": "nickname"
  },
  {
    "kind": "Variable",
    "name": "nullFields",
    "variableName": "nullFields"
  },
  {
    "kind": "Variable",
    "name": "primary",
    "variableName": "primary"
  }
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "missingFrom",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "givenName",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "familyName",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nickname",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "birthdate",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateOfDeath",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "missingSince",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "display",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "primary",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CaseIndividualModalUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v13/*: any*/),
        "concreteType": "CaseIndividualUpdate",
        "kind": "LinkedField",
        "name": "caseIndividualUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CaseIndividual",
            "kind": "LinkedField",
            "name": "caseIndividual",
            "plural": false,
            "selections": [
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CaseIndividualItem_individual"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v7/*: any*/),
      (v1/*: any*/),
      (v8/*: any*/),
      (v6/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v10/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v9/*: any*/),
      (v3/*: any*/),
      (v12/*: any*/),
      (v11/*: any*/)
    ],
    "kind": "Operation",
    "name": "CaseIndividualModalUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v13/*: any*/),
        "concreteType": "CaseIndividualUpdate",
        "kind": "LinkedField",
        "name": "caseIndividualUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CaseIndividual",
            "kind": "LinkedField",
            "name": "caseIndividual",
            "plural": false,
            "selections": [
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c283512d8e3cfa6a853fb284e6d13358",
    "id": null,
    "metadata": {},
    "name": "CaseIndividualModalUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation CaseIndividualModalUpdateMutation(\n  $id: ID\n  $caseId: ID\n  $missingFrom: String\n  $givenName: String\n  $familyName: String\n  $fullName: String\n  $nickname: String\n  $birthdate: Date\n  $dateOfDeath: Date\n  $missingSince: Date\n  $display: Boolean\n  $primary: Boolean\n  $nullFields: [CaseIndividualUpdateNullColumnOption]\n) {\n  caseIndividualUpdate(id: $id, caseId: $caseId, missingFrom: $missingFrom, givenName: $givenName, familyName: $familyName, fullName: $fullName, nickname: $nickname, birthdate: $birthdate, dateOfDeath: $dateOfDeath, missingSince: $missingSince, display: $display, primary: $primary, nullFields: $nullFields) {\n    caseIndividual {\n      id\n      missingFrom\n      givenName\n      familyName\n      fullName\n      nickname\n      birthdate\n      dateOfDeath\n      missingSince\n      display\n      primary\n      ...CaseIndividualItem_individual\n    }\n  }\n}\n\nfragment CaseIndividualItem_individual on CaseIndividual {\n  id\n  missingFrom\n  givenName\n  familyName\n  fullName\n  nickname\n  birthdate\n  dateOfDeath\n  missingSince\n  display\n  primary\n}\n"
  }
};
})();

(node as any).hash = "43ce8311d73a4e70b0a476f59cb0ea28";

export default node;
