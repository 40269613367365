/**
 * @generated SignedSource<<eb735db9595afe3ff5297b711b64d1db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ContributeStep1Mutation$variables = {
  amount: number;
  case: string;
  subscription: boolean;
};
export type ContributeStep1Mutation$data = {
  readonly createPaymentIntent: {
    readonly clientSecret: string | null;
    readonly paymentIntentId: string | null;
  } | null;
};
export type ContributeStep1Mutation = {
  response: ContributeStep1Mutation$data;
  variables: ContributeStep1Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amount"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "case"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subscription"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "amount",
        "variableName": "amount"
      },
      {
        "kind": "Variable",
        "name": "caseId",
        "variableName": "case"
      },
      {
        "kind": "Variable",
        "name": "subscription",
        "variableName": "subscription"
      }
    ],
    "concreteType": "CreatePaymentIntent",
    "kind": "LinkedField",
    "name": "createPaymentIntent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "paymentIntentId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientSecret",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ContributeStep1Mutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ContributeStep1Mutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "3f792fbcc4f19206b1d8a2f71a7d1e4e",
    "id": null,
    "metadata": {},
    "name": "ContributeStep1Mutation",
    "operationKind": "mutation",
    "text": "mutation ContributeStep1Mutation(\n  $amount: Int!\n  $subscription: Boolean!\n  $case: ID!\n) {\n  createPaymentIntent(amount: $amount, subscription: $subscription, caseId: $case) {\n    paymentIntentId\n    clientSecret\n  }\n}\n"
  }
};
})();

(node as any).hash = "b78fbd791637a454affa9e52b7baed13";

export default node;
