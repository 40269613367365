/**
 * @generated SignedSource<<ae3e982d026c6ae4b8841e8d1c6176c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type States_state$data = {
  readonly name: string;
  readonly state: string | null;
  readonly " $fragmentType": "States_state";
};
export type States_state$key = {
  readonly " $data"?: States_state$data;
  readonly " $fragmentSpreads": FragmentRefs<"States_state">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "States_state",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": "state",
      "args": null,
      "kind": "ScalarField",
      "name": "iso3166",
      "storageKey": null
    }
  ],
  "type": "State",
  "abstractKey": null
};

(node as any).hash = "34dd149e82ddea048c7057cbce04764d";

export default node;
