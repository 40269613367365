/**
 * @generated SignedSource<<3253612280b3bde3046604dd094c442e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PreviewEmailNewsletterSendMutation$variables = {
  date?: any | null;
  daysBack?: number | null;
  email: string;
  numFundingItems?: number | null;
  subject: string;
  teaser?: string | null;
};
export type PreviewEmailNewsletterSendMutation$data = {
  readonly sendEmailNewsletter: {
    readonly result: boolean | null;
  } | null;
};
export type PreviewEmailNewsletterSendMutation = {
  response: PreviewEmailNewsletterSendMutation$data;
  variables: PreviewEmailNewsletterSendMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "daysBack"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "numFundingItems"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subject"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "teaser"
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "date",
        "variableName": "date"
      },
      {
        "kind": "Variable",
        "name": "daysBack",
        "variableName": "daysBack"
      },
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "numFundingItems",
        "variableName": "numFundingItems"
      },
      {
        "kind": "Variable",
        "name": "subject",
        "variableName": "subject"
      },
      {
        "kind": "Variable",
        "name": "teaser",
        "variableName": "teaser"
      }
    ],
    "concreteType": "SendEmailNewsletter",
    "kind": "LinkedField",
    "name": "sendEmailNewsletter",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PreviewEmailNewsletterSendMutation",
    "selections": (v6/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "PreviewEmailNewsletterSendMutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "fe074b0174cdfd48f18b4453f8c2c549",
    "id": null,
    "metadata": {},
    "name": "PreviewEmailNewsletterSendMutation",
    "operationKind": "mutation",
    "text": "mutation PreviewEmailNewsletterSendMutation(\n  $email: String!\n  $subject: String!\n  $date: DateTime\n  $teaser: String\n  $daysBack: Int\n  $numFundingItems: Int\n) {\n  sendEmailNewsletter(email: $email, subject: $subject, date: $date, teaser: $teaser, daysBack: $daysBack, numFundingItems: $numFundingItems) {\n    result\n  }\n}\n"
  }
};
})();

(node as any).hash = "25ee24126c807888293e87dcd4633d3b";

export default node;
