/**
 * @generated SignedSource<<16428140e12b24c7c3c6ff7b31f15ea2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CaseQuery$variables = {
  id: string;
};
export type CaseQuery$data = {
  readonly row: {
    readonly caseAgencies?: ReadonlyArray<{
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"CaseAgencyItem_caseAgency">;
    } | null> | null;
    readonly caseArticle?: {
      readonly permalink: string | null;
      readonly slug: string | null;
    } | null;
    readonly description?: string | null;
    readonly individuals?: ReadonlyArray<{
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"CaseIndividualItem_individual">;
    } | null> | null;
    readonly links?: ReadonlyArray<{
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"CaseLinkItem_link">;
    } | null> | null;
    readonly namusId?: string | null;
    readonly othramCaseNumber?: string | null;
    readonly title?: string | null;
  } | null;
};
export type CaseQuery = {
  response: CaseQuery$data;
  variables: CaseQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "namusId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "othramCaseNumber",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "permalink",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "display",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "primary",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CaseQuery",
    "selections": [
      {
        "alias": "row",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CaseLink",
                "kind": "LinkedField",
                "name": "links",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CaseLinkItem_link"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CaseIndividual",
                "kind": "LinkedField",
                "name": "individuals",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CaseIndividualItem_individual"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CaseAgency",
                "kind": "LinkedField",
                "name": "caseAgencies",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CaseAgencyItem_caseAgency"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CaseArticle",
                "kind": "LinkedField",
                "name": "caseArticle",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Case",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CaseQuery",
    "selections": [
      {
        "alias": "row",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CaseLink",
                "kind": "LinkedField",
                "name": "links",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CaseIndividual",
                "kind": "LinkedField",
                "name": "individuals",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "missingFrom",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "givenName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "familyName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nickname",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "birthdate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dateOfDeath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "missingSince",
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CaseAgency",
                "kind": "LinkedField",
                "name": "caseAgencies",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Agency",
                    "kind": "LinkedField",
                    "name": "agency",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "caseNumber",
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CaseArticle",
                "kind": "LinkedField",
                "name": "caseArticle",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Case",
            "abstractKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2c5a6408115008a4fa5dd91507ce0ea8",
    "id": null,
    "metadata": {},
    "name": "CaseQuery",
    "operationKind": "query",
    "text": "query CaseQuery(\n  $id: ID!\n) {\n  row: node(id: $id) {\n    __typename\n    ... on Case {\n      title\n      description\n      namusId\n      othramCaseNumber\n      links {\n        id\n        ...CaseLinkItem_link\n      }\n      individuals {\n        id\n        ...CaseIndividualItem_individual\n      }\n      caseAgencies {\n        id\n        ...CaseAgencyItem_caseAgency\n      }\n      caseArticle {\n        permalink\n        slug\n        id\n      }\n    }\n    id\n  }\n}\n\nfragment CaseAgencyItem_caseAgency on CaseAgency {\n  id\n  agency {\n    name\n    id\n  }\n  caseNumber\n  primary\n  display\n}\n\nfragment CaseIndividualItem_individual on CaseIndividual {\n  id\n  missingFrom\n  givenName\n  familyName\n  fullName\n  nickname\n  birthdate\n  dateOfDeath\n  missingSince\n  display\n  primary\n}\n\nfragment CaseLinkItem_link on CaseLink {\n  id\n  title\n  description\n  url\n  display\n}\n"
  }
};
})();

(node as any).hash = "f99a6d62570757d0419e0d75354f37d5";

export default node;
