/**
 * @generated SignedSource<<99ceb661e3eebdd74456f43abd66fc44>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CaseLinkUpdateNullColumnOption = "DESCRIPTION" | "TITLE" | "%future added value";
export type CaseLinkModalUpdateMutation$variables = {
  caseId?: string | null;
  description?: string | null;
  display?: boolean | null;
  id?: string | null;
  nullFields?: ReadonlyArray<CaseLinkUpdateNullColumnOption | null> | null;
  title?: string | null;
  url?: string | null;
};
export type CaseLinkModalUpdateMutation$data = {
  readonly caseLinkUpdate: {
    readonly caseLink: {
      readonly description: string | null;
      readonly display: boolean;
      readonly id: string;
      readonly title: string | null;
      readonly url: string;
      readonly " $fragmentSpreads": FragmentRefs<"CaseLinkItem_link">;
    } | null;
  } | null;
};
export type CaseLinkModalUpdateMutation = {
  response: CaseLinkModalUpdateMutation$data;
  variables: CaseLinkModalUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "caseId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "display"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nullFields"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "title"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "url"
},
v7 = [
  {
    "kind": "Variable",
    "name": "caseId",
    "variableName": "caseId"
  },
  {
    "kind": "Variable",
    "name": "description",
    "variableName": "description"
  },
  {
    "kind": "Variable",
    "name": "display",
    "variableName": "display"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "nullFields",
    "variableName": "nullFields"
  },
  {
    "kind": "Variable",
    "name": "title",
    "variableName": "title"
  },
  {
    "kind": "Variable",
    "name": "url",
    "variableName": "url"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "display",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CaseLinkModalUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "CaseLinkUpdate",
        "kind": "LinkedField",
        "name": "caseLinkUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CaseLink",
            "kind": "LinkedField",
            "name": "caseLink",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CaseLinkItem_link"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v6/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "CaseLinkModalUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "CaseLinkUpdate",
        "kind": "LinkedField",
        "name": "caseLinkUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CaseLink",
            "kind": "LinkedField",
            "name": "caseLink",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eae5422e90b67338cd567bd571253f86",
    "id": null,
    "metadata": {},
    "name": "CaseLinkModalUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation CaseLinkModalUpdateMutation(\n  $id: ID\n  $caseId: ID\n  $title: String\n  $description: String\n  $url: String\n  $display: Boolean\n  $nullFields: [CaseLinkUpdateNullColumnOption]\n) {\n  caseLinkUpdate(id: $id, caseId: $caseId, title: $title, description: $description, url: $url, display: $display, nullFields: $nullFields) {\n    caseLink {\n      id\n      title\n      description\n      url\n      display\n      ...CaseLinkItem_link\n    }\n  }\n}\n\nfragment CaseLinkItem_link on CaseLink {\n  id\n  title\n  description\n  url\n  display\n}\n"
  }
};
})();

(node as any).hash = "5c9b9d201d8acc7537d57eb7dd2c1155";

export default node;
