/**
 * @generated SignedSource<<f5608af8d5e65bde5646056196a577ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AgencyManagementModalQuery$variables = {
  id: string;
};
export type AgencyManagementModalQuery$data = {
  readonly agency: {
    readonly description?: string | null;
    readonly facebookUrl?: string | null;
    readonly id?: string;
    readonly instagramUrl?: string | null;
    readonly name?: string;
    readonly twitterUrl?: string | null;
    readonly websiteUrl?: string | null;
    readonly wikipediaUrl?: string | null;
  } | null;
};
export type AgencyManagementModalQuery = {
  response: AgencyManagementModalQuery$data;
  variables: AgencyManagementModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wikipediaUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "instagramUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "facebookUrl",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "twitterUrl",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "websiteUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AgencyManagementModalQuery",
    "selections": [
      {
        "alias": "agency",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "type": "Agency",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AgencyManagementModalQuery",
    "selections": [
      {
        "alias": "agency",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "type": "Agency",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9a6d4e573b78f65169ea28c80eae5e64",
    "id": null,
    "metadata": {},
    "name": "AgencyManagementModalQuery",
    "operationKind": "query",
    "text": "query AgencyManagementModalQuery(\n  $id: ID!\n) {\n  agency: node(id: $id) {\n    __typename\n    ... on Agency {\n      id\n      name\n      description\n      wikipediaUrl\n      instagramUrl\n      facebookUrl\n      twitterUrl\n      websiteUrl\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1090fc242cae4101642cb1c5762fc3bf";

export default node;
