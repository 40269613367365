/**
 * @generated SignedSource<<32818e0e9b6de1cec75d077a3bb7cd33>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GeoNewsFeed_geo$data = {
  readonly feedItems: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"GeoNewsFeed_item">;
  } | null> | null;
  readonly " $fragmentType": "GeoNewsFeed_geo";
};
export type GeoNewsFeed_geo$key = {
  readonly " $data"?: GeoNewsFeed_geo$data;
  readonly " $fragmentSpreads": FragmentRefs<"GeoNewsFeed_geo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GeoNewsFeed_geo",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "limit",
          "value": 5
        }
      ],
      "concreteType": "NewsfeedItem",
      "kind": "LinkedField",
      "name": "feedItems",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GeoNewsFeed_item"
        }
      ],
      "storageKey": "feedItems(limit:5)"
    }
  ],
  "type": "State",
  "abstractKey": null
};

(node as any).hash = "3caa24d44368abeac7de944b43a12baa";

export default node;
