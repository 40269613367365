import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useMutation } from 'react-relay';
import { CaseIndividualModalQuery } from './__generated__/CaseIndividualModalQuery.graphql';
import { CaseIndividualModalDeleteMutation } from './__generated__/CaseIndividualModalDeleteMutation.graphql';
import { CaseIndividualModalUpdateMutation, CaseIndividualModalUpdateMutation$variables } from './__generated__/CaseIndividualModalUpdateMutation.graphql';
import { handleError, variablesFromForm } from '../../Utils';
import { useBoolean } from 'usehooks-ts';

interface ModalItemProps {
  id?: string,
  caseId: string,
  setModalId: Function
};

const CaseIndividualModal = ({ id, caseId, setModalId }: ModalItemProps) => {
  const newRecord = id === 'new';
  const { value: show, setFalse: hideShow } = useBoolean(true);
  const closeModal = () => { hideShow(); setModalId(null); };
  id = (newRecord ? undefined : id) as string;

  const data = useLazyLoadQuery<CaseIndividualModalQuery>(
    graphql`
            query CaseIndividualModalQuery($id: ID!, $hasId: Boolean!) {
              caseIndividual: node(id: $id) @include(if: $hasId){
                ... on CaseIndividual {
                  id
                  missingFrom 
                  givenName 
                  familyName
                  fullName
                  nickname
                  birthdate
                  dateOfDeath
                  missingSince
                  display
                  primary
                }
              }
            }
            `, { id: id || '', hasId: !newRecord }
  );
  const caseIndividual = data?.caseIndividual;

  const missingFrom = caseIndividual?.missingFrom || undefined;
  const givenName = caseIndividual?.givenName || undefined;
  const familyName = caseIndividual?.familyName || undefined;
  const fullName = caseIndividual?.fullName || undefined;
  const nickname = caseIndividual?.nickname || undefined;
  const birthdate = caseIndividual?.birthdate || undefined;
  const dateOfDeath = caseIndividual?.dateOfDeath || undefined;
  const missingSince = caseIndividual?.missingSince || undefined;
  const display = caseIndividual?.display || undefined;
  const primary = caseIndividual?.primary || undefined;
  const displayName = fullName || (givenName && familyName && `${givenName} ${familyName}`) || givenName || familyName || nickname;
  const nullableFields = ['missingFrom', 'givenName', 'familyName', 'fullName', 'nickname', 'birthdate', 'dateOfDeath', 'missingSince'];

  const [caseIndividualUpdate, updateInFlight] = useMutation<CaseIndividualModalUpdateMutation>(
    graphql`
          mutation CaseIndividualModalUpdateMutation(
            $id: ID, 
            $caseId: ID, 
            $missingFrom: String, 
            $givenName: String, 
            $familyName: String, 
            $fullName: String, 
            $nickname: String, 
            $birthdate: Date, 
            $dateOfDeath: Date, 
            $missingSince: Date, 
            $display: Boolean,
            $primary: Boolean,
            $nullFields: [CaseIndividualUpdateNullColumnOption]
            ) {
            caseIndividualUpdate(
              id: $id, 
              caseId: $caseId, 
              missingFrom: $missingFrom, 
              givenName: $givenName, 
              familyName: $familyName, 
              fullName: $fullName, 
              nickname: $nickname, 
              birthdate: $birthdate, 
              dateOfDeath: $dateOfDeath, 
              missingSince: $missingSince, 
              display: $display,
              primary: $primary,
              nullFields: $nullFields
              ) {
              caseIndividual{
                id
                missingFrom 
                givenName 
                familyName
                fullName
                nickname
                birthdate
                dateOfDeath
                missingSince
                display
                primary
                ...CaseIndividualItem_individual
              }
            }
          }
          `);

  const [softDelete, deleteInFlight] = useMutation<CaseIndividualModalDeleteMutation>(
    graphql`
      mutation CaseIndividualModalDeleteMutation($id: ID!) {
        softDelete(id: $id) {
          success
        }
      }`);

  const inFlight = updateInFlight || deleteInFlight;

  const btnDelete = () => {
    id && softDelete({
      variables: {
        id
      },
      onCompleted (_, errs) {
        if (!errs) {
          document.location.reload();
        } else {
          handleError(errs[0]);
        }
      },
      onError: handleError
    });

    return false;
  };

  const formRef = React.createRef() as React.RefObject<HTMLFormElement>;

  const frmSubmit = (evt: React.SyntheticEvent) => {
    evt.preventDefault();
    const form = formRef.current as HTMLFormElement;
    const variables = variablesFromForm(form, nullableFields, { agencyName: 'name' }, { caseId, id }) as unknown as CaseIndividualModalUpdateMutation$variables;

    caseIndividualUpdate({
      variables,
      onCompleted (_, errs) {
        if (!errs) {
          document.location.reload();
        } else {
          handleError(errs[0]);
        }
      },
      onError: handleError
    });

    return false;
  };

  return (
    <Modal
      show={show}
      onHide={closeModal}
    >
      <Modal.Header>
        <Modal.Title>
          Editing {newRecord ? 'New Individual' : displayName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form ref={formRef}>
          <Form.Group className='mb-3' controlId='givenName'>
            <Form.Label>Given Name</Form.Label>
            <Form.Control type='text' defaultValue={givenName} />
          </Form.Group>
          <Form.Group className='mb-3' controlId='familyName'>
            <Form.Label>Family Name</Form.Label>
            <Form.Control type='text' defaultValue={familyName} />
          </Form.Group>
          <Form.Group className='mb-3' controlId='fullName'>
            <Form.Label>Full Name</Form.Label>
            <Form.Control type='text' defaultValue={fullName} />
          </Form.Group>
          <Form.Group className='mb-3' controlId='nickname'>
            <Form.Label>Nickname</Form.Label>
            <Form.Control type='text' defaultValue={nickname} />
          </Form.Group>
          <Form.Group className='mb-3' controlId='birthdate'>
            <Form.Label>Birthdate</Form.Label>
            <Form.Control type='date' defaultValue={birthdate} />
          </Form.Group>
          <Form.Group className='mb-3' controlId='dateOfDeath'>
            <Form.Label>Date of Death</Form.Label>
            <Form.Control type='date' defaultValue={dateOfDeath} />
          </Form.Group>
          <Form.Group className='mb-3' controlId='missingSince'>
            <Form.Label>Missing Since</Form.Label>
            <Form.Control type='date' defaultValue={missingSince} />
          </Form.Group>
          <Form.Group className='mb-3' controlId='missingFrom'>
            <Form.Label>Missing From</Form.Label>
            <Form.Control as='textarea' defaultValue={missingFrom} />
          </Form.Group>
          <Form.Group className='mb-3' controlId='display'>
            <Form.Check type='checkbox' label='Display on case page?' defaultChecked={display} />
          </Form.Group>
          <Form.Group className='mb-3' controlId='primary'>
            <Form.Check type='checkbox' label='Is this the main victim?' defaultChecked={primary} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {inFlight
          ? ('Loading')
          : (<>
            {!newRecord && (<Button variant='danger' onClick={btnDelete}>Delete</Button>)}
            <Button onClick={closeModal} variant='secondary'>Cancel <i className='fa fa-ban'></i></Button>
            <Button onClick={frmSubmit}>Save <i className='fa fa-save'></i></Button>
          </>)}
      </Modal.Footer>
    </Modal >
  );
};
(window as any).CaseIndividualModal = CaseIndividualModal;

export default CaseIndividualModal;
