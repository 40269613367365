/**
 * @generated SignedSource<<4fc0216a2133953bed765de5ac770f87>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CaseAgencyUpdateNullColumnOption = "AGENT_IN_CHARGE" | "CASE_NUMBER" | "TIPLINE" | "%future added value";
export type CaseAgencyModalUpdateMutation$variables = {
  agencyId?: string | null;
  agentInCharge?: string | null;
  caseId?: string | null;
  caseNumber?: string | null;
  display?: boolean | null;
  id?: string | null;
  nullFields?: ReadonlyArray<CaseAgencyUpdateNullColumnOption | null> | null;
  primary?: boolean | null;
  tipline?: string | null;
};
export type CaseAgencyModalUpdateMutation$data = {
  readonly caseAgencyUpdate: {
    readonly caseAgency: {
      readonly agentInCharge: string | null;
      readonly caseNumber: string | null;
      readonly display: boolean;
      readonly id: string;
      readonly primary: boolean;
      readonly tipline: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"CaseAgencyItem_caseAgency">;
    } | null;
  } | null;
};
export type CaseAgencyModalUpdateMutation = {
  response: CaseAgencyModalUpdateMutation$data;
  variables: CaseAgencyModalUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "agencyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "agentInCharge"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "caseId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "caseNumber"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "display"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nullFields"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "primary"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tipline"
},
v9 = [
  {
    "kind": "Variable",
    "name": "agencyId",
    "variableName": "agencyId"
  },
  {
    "kind": "Variable",
    "name": "agentInCharge",
    "variableName": "agentInCharge"
  },
  {
    "kind": "Variable",
    "name": "caseId",
    "variableName": "caseId"
  },
  {
    "kind": "Variable",
    "name": "caseNumber",
    "variableName": "caseNumber"
  },
  {
    "kind": "Variable",
    "name": "display",
    "variableName": "display"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "nullFields",
    "variableName": "nullFields"
  },
  {
    "kind": "Variable",
    "name": "primary",
    "variableName": "primary"
  },
  {
    "kind": "Variable",
    "name": "tipline",
    "variableName": "tipline"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "caseNumber",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tipline",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "agentInCharge",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "primary",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "display",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CaseAgencyModalUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "CaseAgencyUpdate",
        "kind": "LinkedField",
        "name": "caseAgencyUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CaseAgency",
            "kind": "LinkedField",
            "name": "caseAgency",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CaseAgencyItem_caseAgency"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v8/*: any*/),
      (v1/*: any*/),
      (v7/*: any*/),
      (v4/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "CaseAgencyModalUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "CaseAgencyUpdate",
        "kind": "LinkedField",
        "name": "caseAgencyUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CaseAgency",
            "kind": "LinkedField",
            "name": "caseAgency",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Agency",
                "kind": "LinkedField",
                "name": "agency",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6ddc41791fc64e62dd5854084abda9b2",
    "id": null,
    "metadata": {},
    "name": "CaseAgencyModalUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation CaseAgencyModalUpdateMutation(\n  $id: ID\n  $caseId: ID\n  $agencyId: ID\n  $caseNumber: String\n  $tipline: String\n  $agentInCharge: String\n  $primary: Boolean\n  $display: Boolean\n  $nullFields: [CaseAgencyUpdateNullColumnOption]\n) {\n  caseAgencyUpdate(id: $id, caseId: $caseId, agencyId: $agencyId, caseNumber: $caseNumber, tipline: $tipline, agentInCharge: $agentInCharge, primary: $primary, display: $display, nullFields: $nullFields) {\n    caseAgency {\n      id\n      caseNumber\n      tipline\n      agentInCharge\n      primary\n      display\n      ...CaseAgencyItem_caseAgency\n    }\n  }\n}\n\nfragment CaseAgencyItem_caseAgency on CaseAgency {\n  id\n  agency {\n    name\n    id\n  }\n  caseNumber\n  primary\n  display\n}\n"
  }
};
})();

(node as any).hash = "a8870af5aca5c05c6fdbff74ee1d135e";

export default node;
