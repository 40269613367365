/**
 * @generated SignedSource<<bcb1b3276725f54abf0d84b964c7b20f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProvincesQuery$variables = {};
export type ProvincesQuery$data = {
  readonly canada: {
    readonly name: string;
    readonly provinces: ReadonlyArray<{
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"Provinces_province">;
    } | null> | null;
  } | null;
};
export type ProvincesQuery = {
  response: ProvincesQuery$data;
  variables: ProvincesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProvincesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Country",
        "kind": "LinkedField",
        "name": "canada",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": "provinces",
            "args": null,
            "concreteType": "State",
            "kind": "LinkedField",
            "name": "states",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Provinces_province"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProvincesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Country",
        "kind": "LinkedField",
        "name": "canada",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": "provinces",
            "args": null,
            "concreteType": "State",
            "kind": "LinkedField",
            "name": "states",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v0/*: any*/),
              {
                "alias": "province",
                "args": null,
                "kind": "ScalarField",
                "name": "iso3166",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c0d7acbe985f2502c17268d98252b949",
    "id": null,
    "metadata": {},
    "name": "ProvincesQuery",
    "operationKind": "query",
    "text": "query ProvincesQuery {\n  canada {\n    name\n    provinces: states {\n      id\n      ...Provinces_province\n    }\n    id\n  }\n}\n\nfragment Provinces_province on State {\n  name\n  province: iso3166\n}\n"
  }
};
})();

(node as any).hash = "6dc257513d670f90d4830154d0334dce";

export default node;
