import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useMutation } from 'react-relay';
import { CaseLinkModalQuery } from './__generated__/CaseLinkModalQuery.graphql';
import { CaseLinkModalDeleteMutation } from './__generated__/CaseLinkModalDeleteMutation.graphql';
import { CaseLinkModalUpdateMutation, CaseLinkModalUpdateMutation$variables } from './__generated__/CaseLinkModalUpdateMutation.graphql';
import { handleError, variablesFromForm, useErrors, PayloadError } from '../../Utils';
import { useBoolean } from 'usehooks-ts';

interface ModalItemProps {
  id?: string,
  caseId: string,
  setModalId: Function
};

const CaseLinkModal = ({ id, caseId, setModalId }: ModalItemProps) => {
  const newRecord = id === 'new';
  const { value: show, setFalse: hideShow } = useBoolean(true);
  const closeModal = () => { hideShow(); setModalId(null); };
  id = (newRecord ? undefined : id) as string;
  const { setError, clearErrorHelper, FormErrorControl } = useErrors();

  const data = useLazyLoadQuery<CaseLinkModalQuery>(
    graphql`
            query CaseLinkModalQuery($id: ID!, $hasId: Boolean!) {
              caseLink: node(id: $id) @include(if: $hasId){
                ... on CaseLink {
                  id
                  title
                  description
                  url
                  display
                }
              }
            }
            `, { id: id || '', hasId: !newRecord }
  );
  const caseLink = data?.caseLink;

  const title = caseLink?.title || undefined;
  const description = caseLink?.description || undefined;
  const url = caseLink?.url || undefined;
  const display = caseLink?.display || undefined;
  const nullableFields = ['title', 'description'];

  const [caseLinkUpdate, updateInFlight] = useMutation<CaseLinkModalUpdateMutation>(
    graphql`
          mutation CaseLinkModalUpdateMutation(
            $id: ID, 
            $caseId: ID, 
            $title: String, 
            $description: String, 
            $url: String, 
            $display: Boolean,
            $nullFields: [CaseLinkUpdateNullColumnOption]
            ) {
            caseLinkUpdate(
              id: $id, 
              caseId: $caseId, 
              title: $title, 
              description: $description, 
              url: $url, 
              display: $display,
              nullFields: $nullFields
              ) {
              caseLink{
                id
                title
                description
                url
                display
                ...CaseLinkItem_link
              }
            }
          }
          `);

  const [softDelete, deleteInFlight] = useMutation<CaseLinkModalDeleteMutation>(
    graphql`
      mutation CaseLinkModalDeleteMutation($id: ID!) {
        softDelete(id: $id) {
          success
        }
      }`);

  const inFlight = updateInFlight || deleteInFlight;

  const btnDelete = () => {
    id && softDelete({
      variables: {
        id
      },
      onCompleted (_, errs) {
        if (!errs) {
          document.location.reload();
        } else {
          handleError(errs[0]);
        }
      },
      onError: handleError
    });

    return false;
  };

  const formRef = React.createRef() as React.RefObject<HTMLFormElement>;

  const frmSubmit = (evt: React.SyntheticEvent) => {
    evt.preventDefault();
    const form = formRef.current as HTMLFormElement;

    const variables = variablesFromForm(form, nullableFields, { linkTitle: 'title' }, { id, caseId }) as unknown as CaseLinkModalUpdateMutation$variables;

    caseLinkUpdate({
      variables,
      onCompleted (_, errs) {
        if (!errs) {
          document.location.reload();
        } else {
          const err = errs[0] as PayloadError;

          if (err?.extensions?.field || err?.extensions?.fields) {
            (err?.extensions?.fields || [err?.extensions?.field]).forEach((field) => {
              setError(field as string, err.message);
            });
          } else {
            handleError();
          }
        }
      },
      onError: handleError
    });

    return false;
  };

  return (
    <Modal
      show={show}
      onHide={closeModal}
    >
      <Modal.Header>
        <Modal.Title>
          Editing {newRecord ? 'New Link' : title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form ref={formRef}>
          <Form.Group className='mb-3' controlId='url'>
            <Form.Label>Url</Form.Label>
            <Form.Control size='lg' type='text' defaultValue={url} required onChange={clearErrorHelper('url')}/>
            {FormErrorControl('url')}
          </Form.Group>
          <Form.Group className='mb-3' controlId='linkTitle'>
            <Form.Label>Title</Form.Label>
            <Form.Control size='lg' type='text' defaultValue={title} required/>
          </Form.Group>
          <Form.Group className='mb-3' controlId='description'>
            <Form.Label>Description</Form.Label>
            <Form.Control as='textarea' defaultValue={description} />
          </Form.Group>
          <Form.Group className='mb-3' controlId='display'>
            <Form.Check type='checkbox' label='Display on case page?' defaultChecked={display} />
          </Form.Group>

        </Form>
      </Modal.Body>
      <Modal.Footer>
        {inFlight
          ? ('Loading')
          : (<>
            {!newRecord && (<Button variant='danger' onClick={btnDelete}>Delete</Button>)}
            <Button onClick={closeModal} variant='secondary'>Cancel <i className='fa fa-ban'></i></Button>
            <Button onClick={frmSubmit}>Save <i className='fa fa-save'></i></Button>
          </>)}
      </Modal.Footer>
    </Modal >
  );
};
(window as any).CaseLinkModal = CaseLinkModal;

export default CaseLinkModal;
