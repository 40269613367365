import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useFragment } from 'react-relay';
import { Table } from 'react-bootstrap';
import { ContentBlocksQuery } from './__generated__/ContentBlocksQuery.graphql';
import { ContentBlocks_block$key as blockFragment } from './__generated__/ContentBlocks_block.graphql';
import { urlFor } from '../../Utils';

interface ItemProps {
  block: blockFragment,
};

const ContentBlockItem = ({ block }: ItemProps) => {
  const { id, identifier, clickCount, impressionCount } = useFragment(
    graphql`
      fragment ContentBlocks_block on ContentBlock {
        id
        identifier
        clickCount
        impressionCount
      }
    `,
    block
  );

  return (
    <tr>
      <td><a href={urlFor('relay.relay_admin_route', { component: 'ContentBlocks', id })} title={id}>[CB {identifier}]</a></td><td>{impressionCount}</td><td>{clickCount}</td>
    </tr>
  );
};

const ContentBlocks = () => {
  const { contentBlocks } = useLazyLoadQuery<ContentBlocksQuery>(
    graphql`
      query ContentBlocksQuery {
        contentBlocks {
          edges {
            node { 
              id
              ...ContentBlocks_block
            }
            cursor
          }
          pageInfo {
            hasNextPage
          }
        }
      }
      `, {}
  );

  const contentBlockRows = (
    contentBlocks?.edges.map((data, key) => data && data.node && <ContentBlockItem block={data.node} key={key} />) ||
    [(<tr key='none'><td colSpan={3}>No content blocks yet. Make one!</td></tr>)]
  );

  return (
    <>
      <div className='hero-content'>
        <div className='container text-dark page-title text-break text-center pt-0'>
          <h1>Content Blocks</h1>
        </div>
      </div>
      <div className='d-flex flex-column'>
        <div className='container px-3 py-3 border bg-white'>
          <Table striped>
            <thead>
              <tr><th>Content Block</th><th>Impressions</th><th>Clicks</th></tr>
            </thead>
            <tbody>
              {contentBlockRows.length > 0 ? contentBlockRows : (<tr><td colSpan={3} className='text-muted'>No content blocks exist yet. Add one below</td></tr>)}
            </tbody>
          </Table>
          <a href={urlFor('relay.relay_admin_route', { component: 'ContentBlocks', id: 'new' })}>New Content Block &raquo;</a>
        </div>
      </div>
    </>
  );
};

(window as any).ContentBlocks = ContentBlocks;

export default ContentBlocks;
