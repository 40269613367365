import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';
import GeoHeader from './GeoHeader';
import WikiDataBox from './WikiDataBox';
import RenderContentBlock from './RenderContentBlock';
import { Container, Row, Col } from 'react-bootstrap';
import GeoCaseCards from './GeoCaseCards';
import CanadianProvincesSvg from './CanadianProvincesSvg';
import { ProvinceQuery } from './__generated__/ProvinceQuery.graphql';
import { urlFor } from '../../Utils';

interface ProvinceProps {
  iso3166: string,
};

const Province = ({ iso3166 }: ProvinceProps) => {
  const contentBlockIdentifier = `geo_ca_${iso3166}`;
  const { canada, contentBlock } = useLazyLoadQuery<ProvinceQuery>(
    graphql`
        query ProvinceQuery($iso3166: String!, $contentBlockIdentifier: String!) {
          canada {
            province: state(iso3166: $iso3166) {
              id
              name
              flagImgUrl
              ...WikiDataBox_geo
              ...GeoHeader_geo
              ...GeoNewsFeed_geo
              ...GeoCaseCards_geo
            }
          }
          contentBlock(identifier: $contentBlockIdentifier){
            ...RenderContentBlock_block
          }
        }
      `,
    { iso3166, contentBlockIdentifier }
  );

  const province = canada?.province;
  const flagImgUrl = urlFor('static', { filename: `img/state_flags/${iso3166}.svg` });

  return (province && <>
    <GeoHeader geo={province} />
    <div className='d-flex flex-column'>
      <Container className='px-3 py-3 border bg-white mb-3'>
        <Row className='flex-lg-row mx-0 mb-3'>
          <Col>
            <CanadianProvincesSvg iso3166={iso3166} />
          </Col>
          <Col className='d-flex flex-column m-auto' md={4}>
            <Col>
              {flagImgUrl && <img src={flagImgUrl} alt={province.name} width='100%' />}
            </Col>
            <Col className='m-1'>
              <WikiDataBox geo={province} />
              {contentBlock && <RenderContentBlock block={contentBlock} />}
            </Col>
          </Col>
        </Row>
        <GeoCaseCards geo={province} /><br />
        <a href={urlFor('geos.provinces')} className='btn btn-sm btn-outline-primary mt-2 ml-3'>&laquo; Back to map</a><br />
      </Container>
    </div>
  </>);
};

(window as any).Province = Province;

export default Province;
