/**
 * @generated SignedSource<<7dab8011218b8c5f8c433c01ae012df1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CaseIndividualModalQuery$variables = {
  hasId: boolean;
  id: string;
};
export type CaseIndividualModalQuery$data = {
  readonly caseIndividual?: {
    readonly birthdate?: string | null;
    readonly dateOfDeath?: string | null;
    readonly display?: boolean;
    readonly familyName?: string | null;
    readonly fullName?: string | null;
    readonly givenName?: string | null;
    readonly id?: string;
    readonly missingFrom?: string | null;
    readonly missingSince?: string | null;
    readonly nickname?: string | null;
    readonly primary?: boolean;
  } | null;
};
export type CaseIndividualModalQuery = {
  response: CaseIndividualModalQuery$data;
  variables: CaseIndividualModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "missingFrom",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "givenName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "familyName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nickname",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "birthdate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateOfDeath",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "missingSince",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "display",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "primary",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CaseIndividualModalQuery",
    "selections": [
      {
        "condition": "hasId",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "caseIndividual",
            "args": (v2/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/)
                ],
                "type": "CaseIndividual",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CaseIndividualModalQuery",
    "selections": [
      {
        "condition": "hasId",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "caseIndividual",
            "args": (v2/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/)
                ],
                "type": "CaseIndividual",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "5a2681d4883ccbd5a7b9004ad45a9a1e",
    "id": null,
    "metadata": {},
    "name": "CaseIndividualModalQuery",
    "operationKind": "query",
    "text": "query CaseIndividualModalQuery(\n  $id: ID!\n  $hasId: Boolean!\n) {\n  caseIndividual: node(id: $id) @include(if: $hasId) {\n    __typename\n    ... on CaseIndividual {\n      id\n      missingFrom\n      givenName\n      familyName\n      fullName\n      nickname\n      birthdate\n      dateOfDeath\n      missingSince\n      display\n      primary\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3a05ae632ad200125d93a614eec74e56";

export default node;
