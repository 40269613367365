/**
 * @generated SignedSource<<82bdfb8d734274d01c3c61737a259e10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StateQuery$variables = {
  contentBlockIdentifier: string;
  iso3166: string;
};
export type StateQuery$data = {
  readonly contentBlock: {
    readonly " $fragmentSpreads": FragmentRefs<"RenderContentBlock_block">;
  } | null;
  readonly usa: {
    readonly state: {
      readonly id: string;
      readonly name: string;
      readonly " $fragmentSpreads": FragmentRefs<"GeoCaseCards_geo" | "GeoHeader_geo" | "GeoNewsFeed_geo" | "WikiDataBox_geo">;
    } | null;
  } | null;
};
export type StateQuery = {
  response: StateQuery$data;
  variables: StateQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contentBlockIdentifier"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "iso3166"
},
v2 = [
  {
    "kind": "Variable",
    "name": "iso3166",
    "variableName": "iso3166"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "identifier",
    "variableName": "contentBlockIdentifier"
  }
],
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "rendered",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "StateQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Country",
        "kind": "LinkedField",
        "name": "usa",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "State",
            "kind": "LinkedField",
            "name": "state",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "WikiDataBox_geo"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "GeoHeader_geo"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "GeoNewsFeed_geo"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "GeoCaseCards_geo"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "ContentBlock",
        "kind": "LinkedField",
        "name": "contentBlock",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RenderContentBlock_block"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "StateQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Country",
        "kind": "LinkedField",
        "name": "usa",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "State",
            "kind": "LinkedField",
            "name": "state",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "population",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "area",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pageBannerImgUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "limit",
                    "value": 5
                  }
                ],
                "concreteType": "NewsfeedItem",
                "kind": "LinkedField",
                "name": "feedItems",
                "plural": true,
                "selections": (v6/*: any*/),
                "storageKey": "feedItems(limit:5)"
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "sortByMagic",
                    "value": true
                  }
                ],
                "concreteType": "CaseArticleConnection",
                "kind": "LinkedField",
                "name": "caseArticles",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CaseArticleEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CaseArticle",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CaseCard",
                            "kind": "LinkedField",
                            "name": "caseCard",
                            "plural": false,
                            "selections": (v6/*: any*/),
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "caseArticles(sortByMagic:true)"
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "ContentBlock",
        "kind": "LinkedField",
        "name": "contentBlock",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "content",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4ea2aeeffac0af9cc6932856ea91f3b7",
    "id": null,
    "metadata": {},
    "name": "StateQuery",
    "operationKind": "query",
    "text": "query StateQuery(\n  $iso3166: String!\n  $contentBlockIdentifier: String!\n) {\n  usa {\n    state(iso3166: $iso3166) {\n      id\n      name\n      ...WikiDataBox_geo\n      ...GeoHeader_geo\n      ...GeoNewsFeed_geo\n      ...GeoCaseCards_geo\n    }\n    id\n  }\n  contentBlock(identifier: $contentBlockIdentifier) {\n    ...RenderContentBlock_block\n    id\n  }\n}\n\nfragment GeoCaseCards_geo on State {\n  name\n  caseArticles(sortByMagic: true) {\n    edges {\n      node {\n        ...GeoCaseCards_item\n        id\n      }\n    }\n  }\n}\n\nfragment GeoCaseCards_item on CaseArticle {\n  caseCard {\n    rendered\n  }\n}\n\nfragment GeoHeader_geo on State {\n  name\n  pageBannerImgUrl\n}\n\nfragment GeoNewsFeed_geo on State {\n  feedItems(limit: 5) {\n    ...GeoNewsFeed_item\n  }\n}\n\nfragment GeoNewsFeed_item on NewsfeedItem {\n  rendered\n}\n\nfragment RenderContentBlock_block on ContentBlock {\n  id\n  content\n}\n\nfragment WikiDataBox_geo on State {\n  population\n  area\n}\n"
  }
};
})();

(node as any).hash = "f5fcb2edbc195a93c2bccddee09ac006";

export default node;
