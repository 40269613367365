import React, { useState } from 'react';
import { Modal, Button, Container } from 'react-bootstrap';
import { urlFor } from '../../Utils';
interface CaseEmbedProps {
  slug: string,
  embedCode: string,
  embedCodeWide: string
};

const activeButtonClass = (active: boolean) => (active ? 'dark-blue' : 'outline-dark-blue');

const EmbedCaseModal = ({ slug, embedCode, embedCodeWide }: CaseEmbedProps) => {
  const [show, setShow] = useState<boolean>(true);
  const [wide, setWide] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);
  const wideClick = setWide.bind(null, true);
  const standardClick = setWide.bind(null, false);
  const setHidden = setShow.bind(null, false);
  const iframeWidth = wide ? 728 : 336;
  const iframeHeight = wide ? 98 : 250;

  const iframeUrl = urlFor('articles.iframe_embed', { slug, wide_variant: wide });
  const embedCodeText = wide ? embedCodeWide : embedCode;
  let hasCopy;
  if (navigator.clipboard) {
    hasCopy = true;
  } else {
    hasCopy = false;
  }

  const copyFunc = (hasCopy
    ? () => {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(embedCodeText);
      }
      setCopied(true);
    }
    : undefined);

  const embedCodeField = (<input type='text' className='form-control form-control-sm' value={embedCodeText} readOnly />);

  return (
    <Modal onHide={setHidden} show={show} size='lg'>
      <Modal.Header closeButton>
        Embed This Case
      </Modal.Header >
      <Modal.Body>
        <div className='form-group'>
          <label htmlFor='embedSelect'>Embed Size: </label><br />
          <div className='btn-group' role='group' aria-label='Embed layout'>
            <Button variant={activeButtonClass(!wide)} onClick={standardClick}>Default</Button>
            <Button variant={activeButtonClass(wide)} onClick={wideClick}>Wide</Button>
          </div>
        </div>
        <div className='mt-5'>
          <Container className='text-center'><iframe src={iframeUrl} width={iframeWidth} height={iframeHeight} frameBorder={0} className='mx-auto' /></Container>
          <label htmlFor='default-embed-script' className='mt-3'>Copy Text Below:</label>
          {hasCopy
            ? (<div className='input-group'>
              {embedCodeField}
              <div className='input-group-append'>
                <Button onClick={copyFunc} variant={activeButtonClass(copied)} >{copied ? 'Copied' : 'Copy'}</Button>
              </div>
            </div>)
            : embedCodeField}
        </div>
      </Modal.Body >
    </Modal >
  );
};

(window as any).EmbedCaseModal = EmbedCaseModal;

export default EmbedCaseModal;
