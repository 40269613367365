import React, { useRef, useState, useEffect } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { Modal, Button, Form, Container, Row, Col } from 'react-bootstrap';
import { useBoolean } from 'usehooks-ts';
import { useStripe, useElements, CardElement, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { PaymentRequestPaymentMethodEvent, PaymentIntent, PaymentRequest, StripeCardElement } from '@stripe/stripe-js';

import { useMutation } from 'react-relay';
import { ContributeStep2Mutation } from './__generated__/ContributeStep2Mutation.graphql';
import { handleError } from '../../Utils';
import urlFor from '../UrlFor';

interface Step2Props {
  subscription: boolean,
  cents: number,
  paymentIntent: PaymentIntent,
  goToNextStep: Function,
  slug: string,
  initialFullName: string,
  initialEmail: string
}
const Step2 = ({
  subscription,
  cents,
  paymentIntent,
  goToNextStep,
  slug,
  initialEmail,
  initialFullName
}: Step2Props) => {
  const amount = (cents / 100).toFixed(2);
  const stripe = useStripe();
  const elements = useElements();
  const canMakePayment = stripe && elements;
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
  const [paymentResult, setPaymentResult] = useState<PaymentIntent>();
  const [email, setEmail] = useState<string>(initialEmail);
  const [cardError, setCardError] = useState<string>();
  const [emailError, setEmailError] = useState<string>();
  const [fullName, setFullName] = useState<string>(initialFullName);
  const canContactRef = useRef<HTMLInputElement>(null);
  const { value: confirmCardPaymentInFlight, setTrue: setConfirmingCardPayment, setFalse: setDone } = useBoolean(false);

  const [completePayment, completePaymentInFlight] = useMutation<ContributeStep2Mutation>(
    graphql`
        mutation ContributeStep2Mutation($email: String!, $name: String!, $canContact: Boolean!, $paymentIntentId: String!) {
          completePayment(email: $email, name: $name, canContact: $canContact, paymentIntentId: $paymentIntentId) {
            result
          }
        }
      `);

  const processing = confirmCardPaymentInFlight || completePaymentInFlight;

  const gotoStep3 = async (paymentIntent: PaymentIntent) => {
    const canContact = !!canContactRef.current?.value;
    completePayment({
      variables: {
        email: email || '',
        name: fullName,
        paymentIntentId: paymentIntent.id,
        canContact
      },
      onCompleted (_, errs) {
        setDone();
        if (!errs) {
          goToNextStep();
        } else {
          const err = errs[0];
          setEmailError(err.message);
        }
      },
      onError: handleError
    });
  };

  const handleSubmit = async (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    if (!canMakePayment) {
      return handleError();
    } else if (paymentResult) {
      return gotoStep3(paymentResult);
    }
    setConfirmingCardPayment();

    const confirmResult = await stripe.confirmCardPayment(paymentIntent.client_secret || '', {
      receipt_email: email,
      payment_method: {
        card: elements.getElement(CardElement) as StripeCardElement,
        billing_details: {
          email: email,
          name: fullName
        }
      }
    });

    if (confirmResult.error) {
      setDone();
      switch (confirmResult.error.type) {
      case 'card_error':
      case 'validation_error':
        setCardError(confirmResult.error.message); break;
      case 'invalid_request_error':
        if (confirmResult.error.code === 'email_invalid') {
          setEmailError('Email address is invalid');
        } else {
          return handleError();
        }
      }
    } else {
      setPaymentResult(confirmResult.paymentIntent);
      gotoStep3(confirmResult.paymentIntent);
    }
  };

  const handleApplePay = async (evt: PaymentRequestPaymentMethodEvent) => {
    if (!canMakePayment) {
      return handleError();
    }

    const confirmResult = await stripe.confirmCardPayment(paymentIntent.client_secret || '', {
      receipt_email: email,
      payment_method: evt.paymentMethod.id
    });

    if (confirmResult.error) {
      evt.complete('fail');
    } else {
      evt.complete('success');
      setPaymentResult(confirmResult.paymentIntent);
      gotoStep3(confirmResult.paymentIntent);
    }
  };

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: slug || 'DNASolves® subscription',
          amount: cents
        },
        requestPayerName: true,
        requestPayerEmail: true
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr);
          pr.on('paymentmethod', handleApplePay);
        }
      });
    }
  }, [stripe]);

  const style = {
    base: {
      color: '#495057',
      fontSize: '1.25rem',
      fontStyle: 'Raleway'
    }
  };
  return (
    <Modal.Body>
      <Container className='mx-0 p-0'>
        <Row>
          <Col className='mx-auto p-0'>
            <Form id='contribution-form' method='post' className='mx-2'>
              <h5 className='py-2 text-center'>Payment Details</h5>
              <h1 className='text-center'>${amount}</h1>
              <Form.Group className='form-row flex-fill mt-3' controlId='first_name'>
                <Form.Label>Full Name</Form.Label>
                <Form.Control onChange={(e) => setFullName(e.target.value)} type='text' value={fullName} />
              </Form.Group>
              <Form.Group className='form-row flex-fill mt-3' controlId='email'>
                <Form.Label>Email Address</Form.Label>
                <Form.Control onChange={(e) => setEmail(e.target.value)} type='email' value={email} />
                <Form.Text className={`invalid-feedback ${emailError ? 'd-block' : ''}`}>{emailError}</Form.Text>
              </Form.Group>
              <div className='d-flex flex-row my-3'>
                <div >
                  <input id='authorize_notifications' name='authorize_notifications' type='checkbox' value='y' ref={canContactRef} />
                </div>
                <div className='ml-2'>
                  I would like to receive updates and news of more cases like this one.
                </div>
              </div>
              <Form.Group className='form-row flex-fill mt-3'>
                <CardElement className='form-control pt-3' options={{ style }} onChange={cardError ? setCardError.bind(null, '') : undefined} />
                <Form.Text className={`invalid-feedback ${cardError ? 'd-block' : ''}`}>{cardError}</Form.Text>
              </Form.Group>
            </Form>
            {paymentRequest && (<PaymentRequestButtonElement options={{
              paymentRequest,
              classes: { base: 'mt-2 w-100' },
              style: { paymentRequestButton: { type: 'donate' } }
            }} />)}
            <Button
              disabled={!canMakePayment || processing}
              onClick={handleSubmit}
              variant='primary'
              className='my-2 w-100' >{processing ? 'Processing' : `Process${subscription ? ' Monthly ' : ' '}Contribution`}</Button>
            <p>
              You can cancel your monthly contribution at any time.
            </p>
            <p>
              To cancel, please enter your email address on the <a href={urlFor('articles.cancel_subscription')} target='_blank' rel='noreferrer'>contribution cancellation page.</a> You will receive an email with further instructions about how to cancel your subscription.
            </p>
          </Col>
        </Row>
      </Container>
    </Modal.Body>
  );
};

export default Step2;
