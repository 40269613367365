/**
 * @generated SignedSource<<7cfb7458e6c64b3c6e504211f287b50a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StatesQuery$variables = {};
export type StatesQuery$data = {
  readonly usa: {
    readonly name: string;
    readonly states: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly " $fragmentSpreads": FragmentRefs<"States_state">;
    } | null> | null;
  } | null;
};
export type StatesQuery = {
  response: StatesQuery$data;
  variables: StatesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StatesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Country",
        "kind": "LinkedField",
        "name": "usa",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "State",
            "kind": "LinkedField",
            "name": "states",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v0/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "States_state"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "StatesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Country",
        "kind": "LinkedField",
        "name": "usa",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "State",
            "kind": "LinkedField",
            "name": "states",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v0/*: any*/),
              {
                "alias": "state",
                "args": null,
                "kind": "ScalarField",
                "name": "iso3166",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "95897e6ab036360d5b3f004247ffe7ef",
    "id": null,
    "metadata": {},
    "name": "StatesQuery",
    "operationKind": "query",
    "text": "query StatesQuery {\n  usa {\n    name\n    states {\n      id\n      name\n      ...States_state\n    }\n    id\n  }\n}\n\nfragment States_state on State {\n  name\n  state: iso3166\n}\n"
  }
};
})();

(node as any).hash = "cf06b3858fb42a4e7e7b42559b3420a8";

export default node;
