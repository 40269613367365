/**
 * @generated SignedSource<<ed27d47dbf739f1d3f87ff4311737c3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CaseIndividualItem_individual$data = {
  readonly birthdate: string | null;
  readonly dateOfDeath: string | null;
  readonly display: boolean;
  readonly familyName: string | null;
  readonly fullName: string | null;
  readonly givenName: string | null;
  readonly id: string;
  readonly missingFrom: string | null;
  readonly missingSince: string | null;
  readonly nickname: string | null;
  readonly primary: boolean;
  readonly " $fragmentType": "CaseIndividualItem_individual";
};
export type CaseIndividualItem_individual$key = {
  readonly " $data"?: CaseIndividualItem_individual$data;
  readonly " $fragmentSpreads": FragmentRefs<"CaseIndividualItem_individual">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CaseIndividualItem_individual",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "missingFrom",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "givenName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "familyName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nickname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "birthdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateOfDeath",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "missingSince",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "display",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "primary",
      "storageKey": null
    }
  ],
  "type": "CaseIndividual",
  "abstractKey": null
};

(node as any).hash = "ec8cd7a9f427bac6a1b87ad23368cd55";

export default node;
