import React, { useState, useEffect } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';
import { PreviewEmailNewsletterSendMutation } from './__generated__/PreviewEmailNewsletterSendMutation.graphql';

interface ItemProps {
  defaultNewsletterSubject: string,
  defaultTeaserLine: string,
  pageTitle: string,
  defaultNumFundingItems: number,
  defaultDaysBack: number
};

const PreviewEmailNewsletter = ({ pageTitle, defaultNewsletterSubject, defaultTeaserLine, defaultNumFundingItems, defaultDaysBack }: ItemProps) => {
  const [date, setDate] = useState<Date>(new Date());
  const [daysBack, setDaysBack] = useState<number>(defaultDaysBack);
  const [numFundingItems, setNumFundingItems] = useState<number>(defaultNumFundingItems);
  const [previewState, setPreview] = useState<boolean>(document.location.hash === '#text');
  const textPreview = previewState ? 'active' : '';
  const htmlPreview = !previewState ? 'active' : '';
  const previewExt = previewState ? 'txt' : 'html';

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  const [sendEmail, emailSending] = useMutation<PreviewEmailNewsletterSendMutation>(
    graphql`
      mutation PreviewEmailNewsletterSendMutation($email: String!, $subject: String!, $date:DateTime, $teaser: String, $daysBack: Int, $numFundingItems: Int) {
        sendEmailNewsletter(email: $email, subject: $subject, date: $date, teaser: $teaser, daysBack: $daysBack, numFundingItems: $numFundingItems) {
          result
        }
      }
    `);

  const frmSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    const frm = evt.target as HTMLFormElement;
    evt.preventDefault();

    sendEmail({
      variables: {
        email: frm.email.value,
        subject: frm.subject.value,
        date: frm.date.value,
        teaser: frm.teaser.value,
        daysBack: frm.daysBack.value,
        numFundingItems: frm.numFundingItems.value
      },
      // autoformatter will always remove whitespace, so disabling eslint for next line
      onCompleted (_, errs) { // eslint-disable-line
        if (!errs) {
          alert('Test email sent!');
        } else {
          alert('Something wrong call tech support');
        }
      }
    });

    return false;
  };

  const btnConfirmSend = () => {
    return confirm('This will send the test email\nAre you sure?');
  };
  const querystring = new URLSearchParams({
    date: date.toISOString(), days_back: daysBack.toString(), num_funding_items: numFundingItems.toString()
  });

  const nonNegativeInt = (val: number | string) => {
    val = parseInt(val as string);
    return (val >= 0) ? val : 0;
  };

  return (
    <div className='container mt-5'>
      <div className='row'>
        <div className='col'>
          <form onSubmit={frmSubmit}>
            <div className='form-group'>
              <label htmlFor='email'>Address to send text to:</label>
              <input className='form-control' type='text' name='email' placeholder='Test email address' required />
            </div>
            <div className='form-group'>
              <label htmlFor='subject'>Subject for test email:</label>
              <input className='form-control' type='text' name='subject' placeholder='Test email subject' defaultValue={defaultNewsletterSubject} required />
            </div>
            <div className='form-group'>
              <label htmlFor='subject'>Teaser <span className='text-muted'>(Optional)</span>:</label>
              <input className='form-control' type='text' name='teaser' placeholder='Teaser line (shows in gmail preview)' defaultValue={defaultTeaserLine} />
            </div>
            <div className='form-group'>
              <label htmlFor='subject'>Number of days back <span className='text-muted'>(Optional)</span>:</label>
              <input className='form-control' type='number' min={0} max={30} value={daysBack} onChange={e => setDaysBack(nonNegativeInt(e.target.value))} name='daysBack' placeholder='How many days back' />
            </div>
            <div className='form-group'>
              <label htmlFor='subject'>Number of funding items <span className='text-muted'>(Optional)</span>:</label>
              <input className='form-control' type='number' min={1} max={10} value={numFundingItems} onChange={e => setNumFundingItems(nonNegativeInt(e.target.value))} name='numFundingItems' placeholder='How many funding items' />
            </div>
            <div className='form-group'>
              <label htmlFor='subject'>From date: (in UTC)</label>
              <input className='form-control' type='datetime-local' name='date' value={date.toISOString().slice(0, -8)} onChange={e => setDate(new Date(Date.parse(e.target.value)))} placeholder='Pretend its this date/time' />
            </div>
            {emailSending
              ? (<h1>Sending...</h1>)
              : (<>
                <button
                  onClick={btnConfirmSend}
                  type='submit'
                  className='btn btn-primary'
                >Send Test Newsletter</button>
              </>
              )}
          </form>
        </div>
        <div className='col' style={{ height: '1000px' }}>
          <h2>Preview:</h2>
          <ul className='nav nav-tabs'>
            <li className='nav-item'>
              <a className={`nav-link ${htmlPreview}`} onClick={setPreview.bind(this, false)} href='#preview_html'>HTML</a>
            </li>
            <li className='nav-item'>
              <a className={`nav-link ${textPreview}`} onClick={setPreview.bind(this, true)} href='#preview_text'>Text</a>
            </li>
          </ul>
          <iframe src={`/email/preview_newsletter.${previewExt}?${querystring}`} className='w-100' style={{ height: '90%' }} />
        </div>
      </div>
    </div>
  );
};

export default PreviewEmailNewsletter;
