/**
 * @generated SignedSource<<1d51aa8970ca4b26d61d670723d2c7c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ContentBlockQuery$variables = {
  id: string;
};
export type ContentBlockQuery$data = {
  readonly contentBlock: {
    readonly clickCount?: number | null;
    readonly content?: string;
    readonly dateArchived?: any | null;
    readonly draft?: boolean;
    readonly identifier?: string | null;
    readonly impressionCount?: number | null;
  } | null;
};
export type ContentBlockQuery = {
  response: ContentBlockQuery$data;
  variables: ContentBlockQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identifier",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "impressionCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clickCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateArchived",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "draft",
      "storageKey": null
    }
  ],
  "type": "ContentBlock",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContentBlockQuery",
    "selections": [
      {
        "alias": "contentBlock",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContentBlockQuery",
    "selections": [
      {
        "alias": "contentBlock",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "66c5f2b74f15a4c26faef60589d3b692",
    "id": null,
    "metadata": {},
    "name": "ContentBlockQuery",
    "operationKind": "query",
    "text": "query ContentBlockQuery(\n  $id: ID!\n) {\n  contentBlock: node(id: $id) {\n    __typename\n    ... on ContentBlock {\n      identifier\n      content\n      impressionCount\n      clickCount\n      dateArchived\n      draft\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0e4482a57cbf5d7476c8e23e2fe98dff";

export default node;
