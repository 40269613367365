import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { urlFor } from '../../Utils';

const ContributionThanksBody = () => {
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Thank You For Your Contribution</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6>What else can I do?</h6>
        <div className='flex-fill' >You can help us solve more cases by contributing your DNA.</div>
      </Modal.Body>
      <Modal.Footer>
        <Button className='btn-primary w-100' href={urlFor('user.register')}>
          Contribute DNA&nbsp;<i className='fas fa-lg fa-dna' />
        </Button>
      </Modal.Footer>
    </>
  );
};

const ContributionThanks = () => {
  const [show, setShow] = useState<boolean>(true);
  const handleClose = () => setShow(false);
  return (
    <Modal onHide={handleClose} show={show}>
      <ContributionThanksBody/>
    </Modal>
  );
};

(window as any).ContributionThanks = ContributionThanks;

export { ContributionThanks as default, ContributionThanksBody };
